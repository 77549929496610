import getTermsOfUseUrl from '../../../lib/getTermsOfUseUrl';
import htmlDecode from '@/lib/htmlDecode';

/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.AttendanceRequestController
 * @description This controller is used for attendance requests. This mainly
 *              deals with creating attendance request.
 * @requires attendanceRequestService,userGroupService,fileUploadSvc are the
 *           service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires $window
 * @requires AuthService to logout
 */
angular
  .module('generalMeetingHomeModule')
  .controller('AttendanceRequestController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    '$state',
    '$log',
    'attendanceRequestService',
    'fileUploadSvc',
    '$filter',
    'userGroupService',
    'LanguagePreferenceService',
    '$timeout',
    'myVoteService',
    'voteInstructionService',
    'generalMeetingService',
    'intermediaryService',
    '$window',
    'AuthService',
    'participationMethodService',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      $state,
      $log,
      attendanceRequestService,
      fileUploadSvc,
      $filter,
      userGroupService,
      LanguagePreferenceService,
      $timeout,
      myVoteService,
      voteInstructionService,
      generalMeetingService,
      intermediaryService,
      $window,
      AuthService,
      participationMethodService
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.securityAccountValidation = validationRegex.SECURITY_ACCOUNT_NR;
      $scope.attendanceData = $stateParams.attendanceDataDeatils;
      // Below code is added for default selection of Intermediary
      //and share class for linked meeting SFCBP-2725
      var linkedISIN = $stateParams.linkedISIN;
      var selectedIntermediaryName = $stateParams.selectedIntermediaryName;
      $scope.sharePlanId = $stateParams.shareplanId;
      $scope.issuerId = $stateParams.issuerId;
      $scope.issuerName = $stateParams.issuerName;
      $scope.gmId = $stateParams.gmId;
      $scope.userType = $sessionStorage.usertype;
      $scope.fromPage = $stateParams.fromPage;
      $scope.spVoteEnabled = $stateParams.spVoteEnabled;
      $scope.isVirtualMeetingEnabled = $stateParams.virtualMeetingFlag;
      $scope.isVotingEnable = false;
      $scope.attendProxyBy = 'none';
      $scope.shareHolderVO = {};
      $scope.securities = {};
      $scope.proxyDetails = [];
      $scope.profileDetails = {};
      $scope.createProxy = false;
      $scope.selectProxy = false;
      $scope.proxyDefaultDetails = false;
      $scope.showIntermediary = true;
      $scope.duplicateRequest = false;
      $scope.intRequestId = null;
      $scope.selectedShareClassId = null;
      $scope.linkedMeeting = false;
      $scope.holding = 'entire';
      $scope.loading = false;
      $scope.loadingSpin = false;
      $scope.loadingFileSpin = false;
      $scope.disableAddSection = false;
      $scope.hideInteDropDown = false;
      $scope.limitExceededObj = null;
      $scope.logoutURL = $sessionStorage.workstationData.menuData.logOut;
      $scope.proxyType = $sessionStorage.proxyType;
      $scope.linkedIntError = '';
      //Below code is added for ForFarmer default proxy changes
      $scope.isForFarmer = false;
      $scope.isTriodos = false;
      $scope.isSaatTriodos = false;
      var farmerProxySelected = false;
      $scope.languageSelected = $sessionStorage.selectedLang || 'EN';
      $scope.eventType = 'Footer Term of Use';


      $scope.checkParticularCases = function() {
        //SFCBP-2622 issue fixed when issuer name is not there
        if (
            $scope.issuerName &&
            $scope.issuerName.toLowerCase() === 'forfarmers n.v.'
        ) {
          $scope.isForFarmer = true;
        }
        if (
            $scope.issuerName &&
            $scope.issuerName.toLowerCase() === 'triodos bank n.v.'
        ) {
          $scope.isTriodos = true;
        }
        if (
            $scope.issuerName &&
            $scope.issuerName.toLowerCase() ===
            'stichting administratiekantoor aandelen triodos bank'
        ) {
          $scope.isSaatTriodos = true;
        }
      };

      $scope.handleMeetings = function() {
        //Below value is hard coded to GM because VI and AR functionalities will be there only for GM users.
        //Using productType array from session storage was causing issue in case of integrated users, so it is relpaced with below value.
        $scope.productTypeToU = 'GM';
        // Below if condition handles the shareplan holder related functionalities
        if (
            $sessionStorage.gmId &&
            $sessionStorage.issuerId &&
            $sessionStorage.usertype === 'SP'
        ) {
          $scope.onLoadSpinnerAttend = true;
          generalMeetingService
          .getGeneralMeetingView($sessionStorage.gmId)
          .query()
          .$promise.then(
              function (data) {
                $scope.onLoadSpinnerAttend = false;
                $scope.generalMeetingView = data;
                $scope.spVoteEnabled = data.spVoteEnabled;
                var todaysDate = new Date();
                todaysDate.setHours(0);
                todaysDate.setMinutes(0);
                todaysDate.setSeconds(0);
                var todayDateTime = new Date();
                todayDateTime.setSeconds(0);
                var shareholderAccessDate = new Date(
                    $scope.generalMeetingView.gmScheduleVO.shareholderAccessDate
                );
                var shareholderDeadlineDate = new Date(
                    $scope.generalMeetingView.gmScheduleVO.shareholderDeadlineDate
                );
                if (shareholderAccessDate > todaysDate) {
                  $scope.disableConfirm = true;
                  $scope.requestError =
                      $filter('translate')('label_youCanAttend') +
                      $scope.generalMeetingView.gmScheduleVO.shareholderAccessDate;
                } else if (
                    shareholderDeadlineDate.getTime() < todayDateTime.getTime() &&
                    todayDateTime.toString() !== shareholderDeadlineDate.toString()
                ) {
                  $scope.disableConfirm = true;
                  $scope.requestError =
                      $filter('translate')('label_dealineDateError') +
                      $scope.generalMeetingView.gmScheduleVO
                          .shareholderDeadlineDate;
                }
                if ($scope.disableConfirm) {
                  $scope.modalBody = $scope.requestError;
                  $scope.showOpenAlertModalAttend = true;
                }
              },
              function (error) {
                $log.debug(error);
                $scope.onLoadSpinnerAttend = false;
              }
          );
        }
      };

      $scope.setProxyForFarmer = function() {
        $scope.checkForFarmerProxy = $filter('filter')(
            response.data,
            {
              proxyName: 'Coöperatie FromFarmers UA',
            },
            true
        );
        if (
            $scope.checkForFarmerProxy.length === 0 &&
            ($sessionStorage.usertype === 'IN' || $scope.isForFarmer)
        ) {
          $scope.proxyDetails = $scope.proxyDetails.concat(
              proxyForFarmers
          );
        }
      };

      $scope.setupIntermediary = function() {
        if ($sessionStorage.usertype === 'IN' && !$scope.isForFarmer) {
          $scope.selectedProxyList = $filter('filter')(
              $scope.proxyDetails,
              {
                proxyName: 'Elke Vertegenwoordiger Van De VEB',
              },
              true
          );
          $scope.selectedProxyCountry = $filter('filter')(
              $scope.attendanceData.countryCodeVO,
              {
                isoCountryCode: 'NLD',
              },
              true
          );
          $scope.selectedProxyCity = 'Den Haag';
          $scope.selectedProxyCode = $filter('filter')(
              $scope.attendanceData.proxyTypeVO,
              {
                proxyTypeCode: 'LGL',
              },
              true
          );
        } else if ($scope.isForFarmer) {
          farmerProxySelected = true;
          $scope.selectedProxyList = $filter('filter')(
              $scope.proxyDetails,
              {
                proxyName: 'Coöperatie FromFarmers UA',
              },
              true
          );
          $scope.selectedProxyCountry = $filter('filter')(
              $scope.attendanceData.countryCodeVO,
              {
                isoCountryCode: 'NLD',
              },
              true
          );
          $scope.selectedProxyCity = 'Lochem';
          $scope.selectedProxyRepresentativeName =
              'Iedere vertegenwoordiger van de Cöoperatie FromFarmers';
          $scope.selectedProxyCode = $filter('filter')(
              $scope.attendanceData.proxyTypeVO,
              {
                proxyTypeCode: 'LGL',
              },
              true
          );
        }
      };

      $scope.attandanceProxyCallback = function() {
        return function (response) {
          //Below changes are added to remove default proxy value for shareholder WS
          if (
              response.data.length === 0 &&
              ($sessionStorage.usertype === 'IN' || $scope.isForFarmer)
          ) {
            //Below code is added for ForFarmer default proxy chnages
            if ($scope.isForFarmer) {
              $scope.proxyDetails = $scope.proxyDetails.concat(
                  proxyForFarmers
              );
            }
            if ($sessionStorage.usertype === 'IN') {
              $scope.proxyDetails = $scope.proxyDetails.concat(defautProxy);
            }
            $scope.selectedCountry = $filter('filter')(
                $scope.attendanceData.countryCodeVO,
                {
                  isoCountryCode: 'NLD',
                },
                true
            );
          } else {
            $scope.checkForDefualtProxy = $filter('filter')(
                response.data,
                {
                  proxyName: 'Elke Vertegenwoordiger Van De VEB',
                },
                true
            );
            $scope.proxyDetails = response.data;
            //Below code is added for ForFarmer default proxy chnages
            if ($scope.isForFarmer) {
              setProxyForFarmer();
            }
            if (
                $scope.checkForDefualtProxy.length === 0 &&
                $sessionStorage.usertype === 'IN'
            ) {
              $scope.proxyDetails = $scope.proxyDetails.concat(defautProxy);
            }
          }
          //Below changes are added to remove default proxy value for shareholder WS
          //$scope.isForFarmer - is added for ForFarmer default proxy chnages
          farmerProxySelected = false;
          $scope.setupIntermediary();

          if ($stateParams.isLinkedMeeting) {
            importLinkedMeetingParamsIntoScope();
          }

          if ($scope.proxyDetails.length !== 0 && $sessionStorage.usertype === 'SH'
              && $scope.attendanceData.sharePlanHolderVO.shareHolderType === 'LGL') {
            // legal shareholder have to permit only natural person as proxy
            $scope.proxyDetails = $filter('filter')(
                $scope.proxyDetails, {proxyType: 'NAT'}, true);
          }
        };
      };

// Below is the function to submit attendance request in different workstations
      $scope.submitAttendanceRequest = function () {
        if ($scope.titleRequired) {
          $scope.proxyTitle = $scope.selectedProxyTitle.title;
        } else {
          $scope.proxyTitle = null;
        }
        if ($scope.shTitleRequired) {
          $scope.shTitle = $scope.shareHolderVO.selectedTitle[0].title;
        } else {
          $scope.shTitle = null;
        }
        $scope.response = null;
        $scope.error = null;
        $scope.errorCode = null;
        $scope.loadingSpin = true;
        //Below changes are added to remove default proxy value for shareholder WS
        if (
            $scope.selectProxy &&
            ($sessionStorage.usertype === 'IN' || $scope.isForFarmer) &&
            !$scope.selectedProxyList[0].proxyId
        ) {
          //farmerProxySelected - is added for ForFarmer default proxy chnages
          $scope.createNewProxy = 'Y';
          $scope.legalEntityIdentifier = null;
          $scope.isoCountrycode = 'NLD';
          $scope.proxyTypeCode = 'LGL';
          if (farmerProxySelected) {
            $scope.proxyCity = 'Lochem';
            $scope.proxyName = 'Coöperatie FromFarmers UA';
            $scope.representativeName =
                'Iedere vertegenwoordiger van de Cöoperatie FromFarmers';
          } else {
            $scope.proxyCity = 'Den Haag';
            $scope.proxyName = 'Elke Vertegenwoordiger Van De VEB';
            $scope.representativeName = null;
          }
        }
        if ($scope.createProxy) {
          $scope.isoCountrycode = $scope.selectedProxyCountry.isoCountryCode;
          $scope.proxyTypeCode = $scope.selectedProxyType.proxyTypeCode;
        }
        if ($scope.userType === 'IN') {
          if (
              isNaN($scope.profileDetails.dob) &&
              $scope.profileDetails.dob !== undefined
          ) {
            $scope.dob = $scope.profileDetails.dob.getTime();
          } else {
            $scope.dob = $scope.profileDetails.dob;
          }
          $scope.intermediaryId = $sessionStorage.groupId;
          if ($scope.profileDetails.shareholderId !== '0') {
            $scope.shareholderId = $scope.profileDetails.shareholderId;
          } else {
            $scope.shareholderId = '0';
          }
          if ($scope.duplicateRequest) {
            $scope.shareholderId = null;
          }
          //SFCBP-2488 confirm/save button not enabled for normal intermediary
          let intermediaryId = '';
          let intermediaryName = '';
          let intermediaryUuid = '';
          if ($scope.selectedLinkedInt) {
            intermediaryId = $scope.selectedLinkedInt.intermediaryId;
            intermediaryUuid = $scope.selectedLinkedInt.intermediaryUuid;
            intermediaryName = $scope.selectedLinkedInt.intermediaryName;
          } else {
            intermediaryId = $scope.intermediaryId;
            intermediaryUuid = $scope.intermediaryUuid;
          }
          if ($scope.proxyProvided === 'Y' && $scope.createNewProxy === 'Y') {
            $scope.attendanceRequestData = {
              intermediaryId: intermediaryId,
              intermediaryUuid: intermediaryUuid,
              intermediaryName: intermediaryName,
              meetingId: $scope.gmId,
              issuerName: $scope.issuerName,
              issuerId: $scope.issuerId,
              noOfSecurities: $scope.numberOfShares,
              attendanceId: null,
              isin: $scope.isin,
              isRegisterShareclass:
              $scope.selectedShareClass.isRegisterShareclass,
              securityAccNo: $scope.securityAccountNumber,
              filePath: $scope.filePath,
              fileStatus: $scope.fileStatus,
              fileName: $scope.fileName,
              gmDate: $stateParams.gmDate,
              rightToSpeak: false,
              shareHolderVOList: [
                {
                  isSharePlanUser: 'N',
                  proxyProvided: $scope.proxyProvided,
                  createNewProxy: $scope.createNewProxy,
                  shareholderId: $scope.shareholderId,
                  shareclassId: $scope.selectedShareClass.shareclassId,
                  shareHolderType: $scope.shareHolderType[0]?.proxyTypeCode,
                  address: $scope.profileDetails.address,
                  city: $scope.profileDetails.city,
                  country: $scope.selectedCountry[0]?.isoCountryCode,
                  emailAddress: $scope.profileDetails.emailAddress,
                  firstName: $scope.profileDetails.firstName,
                  initials: $scope.shareHolderVO.initials,
                  lastName: $scope.profileDetails.lastName,
                  contactPerson: $scope.profileDetails.contactPerson,
                  legalEntityIdentifier:
                  $scope.profileDetails.legalEntityIdentifier,
                  owneType: $scope.selectedUserType[0]?.userType,
                  phoneNumber: $scope.profileDetails.phoneNumber,
                  postalCode: $scope.profileDetails.postalCode,
                  securityAccountNumber: $scope.securityAccountNumber,
                  dob: $scope.dob,
                  title: $scope.shTitle,
                  isin: $scope.isin,
                  proxyCity: $scope.proxyCity,
                  proxyCountry: $scope.isoCountrycode,
                  proxyInitials: $scope.proxyInitial,
                  proxyType: $scope.proxyTypeCode,
                  proxyName: $scope.proxyName,
                  proxyTitle: $scope.proxyTitle,
                  representativeName: $scope.representativeName,
                  legalIdentifier: $scope.legalIdentifier,
                },
              ],
            };
          } else if (
              $scope.proxyProvided === 'Y' &&
              $scope.createNewProxy === 'N'
          ) {
            $scope.attendanceRequestData = {
              intermediaryId: intermediaryId,
              intermediaryUuid: intermediaryUuid,
              intermediaryName: intermediaryName,
              meetingId: $scope.gmId,
              issuerName: $scope.issuerName,
              issuerId: $scope.issuerId,
              noOfSecurities: $scope.numberOfShares,
              attendanceId: null,
              isin: $scope.isin,
              isRegisterShareclass:
              $scope.selectedShareClass.isRegisterShareclass,
              securityAccNo: $scope.securityAccountNumber,
              filePath: $scope.filePath,
              fileStatus: $scope.fileStatus,
              fileName: $scope.fileName,
              gmDate: $stateParams.gmDate,
              rightToSpeak: false,
              shareHolderVOList: [
                {
                  isSharePlanUser: 'N',
                  proxyProvided: $scope.proxyProvided,
                  createNewProxy: $scope.createNewProxy,
                  shareholderId: $scope.shareholderId,
                  shareclassId: $scope.selectedShareClass.shareclassId,
                  shareHolderType: $scope.shareHolderType[0]?.proxyTypeCode,
                  address: $scope.profileDetails.address,
                  city: $scope.profileDetails.city,
                  country: $scope.selectedCountry[0]?.isoCountryCode,
                  emailAddress: $scope.profileDetails.emailAddress,
                  firstName: $scope.profileDetails.firstName,
                  initials: $scope.shareHolderVO.initials,
                  lastName: $scope.profileDetails.lastName,
                  owneType: $scope.selectedUserType[0]?.userType,
                  phoneNumber: $scope.profileDetails.phoneNumber,
                  postalCode: $scope.profileDetails.postalCode,
                  securityAccountNumber: $scope.securityAccountNumber,
                  dob: $scope.dob,
                  title: $scope.shTitle,
                  isin: $scope.isin,
                  proxyId: $scope.selectedProxyList[0].proxyId,
                },
              ],
            };
          } else {
            $scope.attendanceRequestData = {
              intermediaryId: intermediaryId,
              intermediaryUuid: intermediaryUuid,
              intermediaryName: intermediaryName,
              meetingId: $scope.gmId,
              issuerName: $scope.issuerName,
              issuerId: $scope.issuerId,
              noOfSecurities: $scope.numberOfShares,
              attendanceId: null,
              isin: $scope.isin,
              isRegisterShareclass:
              $scope.selectedShareClass.isRegisterShareclass,
              securityAccNo: $scope.securityAccountNumber,
              filePath: $scope.filePath,
              fileStatus: $scope.fileStatus,
              fileName: $scope.fileName,
              gmDate: $stateParams.gmDate,
              rightToSpeak: false,
              shareHolderVOList: [
                {
                  isSharePlanUser: 'N',
                  proxyProvided: 'N',
                  createNewProxy: 'N',
                  shareholderId: $scope.shareholderId,
                  shareclassId: $scope.selectedShareClass.shareclassId,
                  shareHolderType: $scope.shareHolderType?.[0]?.proxyTypeCode,
                  address: $scope.profileDetails.address,
                  city: $scope.profileDetails.city,
                  country: $scope.selectedCountry?.[0]?.isoCountryCode,
                  emailAddress: $scope.profileDetails.emailAddress,
                  firstName: $scope.profileDetails.firstName,
                  initials: $scope.shareHolderVO.initials,
                  lastName: $scope.profileDetails.lastName,
                  owneType: $scope.selectedUserType?.[0]?.userType,
                  phoneNumber: $scope.profileDetails.phoneNumber,
                  postalCode: $scope.profileDetails.postalCode,
                  securityAccountNumber: $scope.securityAccountNumber,
                  dob: $scope.dob,
                  title: $scope.shTitle,
                  isin: $scope.isin,
                },
              ],
            };
          }
        } else if ($scope.userType === 'SP') {
          $scope.intermediaryId = null;
          $scope.intermediaryUuid = null;
          $scope.shareholderId = $sessionStorage.userId;
          if ($scope.proxyProvided === 'Y' && $scope.createNewProxy === 'Y') {
            $scope.attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $scope.gmId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $scope.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              intermediaryUuid: $scope.intermediaryUuid,
              attendanceId: null,
              isin: $scope.isin,
              initiatedThruShrhWrkst: 'N',
              noOfSecurities: $scope.numberOfShares,
              gmDate: $stateParams.gmDate,
              shareHolderVOList: [
                {
                  isSharePlanUser: 'Y',
                  proxyProvided: $scope.proxyProvided,
                  createNewProxy: $scope.createNewProxy,
                  shareholderId: $sessionStorage.groupId,
                  shareclassId: $scope.selectedShareClass.shareclassId,
                  isin: $scope.isin,
                  proxyCity: $scope.proxyCity,
                  proxyCountry: $scope.isoCountrycode,
                  proxyInitials: $scope.proxyInitial,
                  proxyType: $scope.proxyTypeCode,
                  proxyName: $scope.proxyName,
                  proxyTitle: $scope.proxyTitle,
                  representativeName: $scope.representativeName,
                  legalIdentifier: $scope.legalEntityIdentifier,
                },
              ],
            };
          } else if (
              $scope.proxyProvided === 'Y' &&
              $scope.createNewProxy === 'N'
          ) {
            $scope.attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $scope.gmId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $scope.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              intermediaryUuid: $scope.intermediaryUuid,
              attendanceId: null,
              isin: $scope.isin,
              initiatedThruShrhWrkst: 'N',
              noOfSecurities: $scope.numberOfShares,
              gmDate: $stateParams.gmDate,
              shareHolderVOList: [
                {
                  isSharePlanUser: 'Y',
                  proxyProvided: $scope.proxyProvided,
                  createNewProxy: $scope.createNewProxy,
                  shareholderId: $sessionStorage.groupId,
                  shareclassId: $scope.selectedShareClass.shareclassId,
                  isin: $scope.isin,
                  proxyId: $scope.selectedProxyList[0].proxyId,
                },
              ],
            };
          } else {
            $scope.attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $scope.gmId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $scope.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              intermediaryUuid: $scope.intermediaryUuid,
              attendanceId: null,
              isin: $scope.isin,
              initiatedThruShrhWrkst: 'N',
              noOfSecurities: $scope.numberOfShares,
              gmDate: $stateParams.gmDate,
              shareHolderVOList: [
                {
                  isSharePlanUser: 'Y',
                  proxyProvided: 'N',
                  createNewProxy: 'N',
                  shareholderId: $sessionStorage.groupId,
                  shareclassId: $scope.selectedShareClass.shareclassId,
                  isin: $scope.isin,
                },
              ],
            };
          }
        } else {// userType = SH
          $scope.shareholderId = $sessionStorage.userId;
          if ($scope.holding === 'entire') {
            $scope.numberOfShares = 0;
          } else {
            $scope.numberOfShares = $scope.securities.numberOfShares;
          }
          if ($scope.selectedIntermediaryFlag && !$scope.addIntermediaryFlag) {
            $scope.intermediaryId = $scope.selectedIntermediary.intermediaryId;
            $scope.intermediaryUuid = $scope.selectedIntermediary.intermediaryUuid;
          } else if (!$scope.intermediaryId) {
            $scope.intermediaryId =
                $scope.selectedShareClass.intermediaryVO[0].intermediaryId;
            $scope.intermediaryUuid =
                $scope.selectedShareClass.intermediaryVO[0].intermediaryUuid;
          } else if ($scope.addIntermediaryFlag) {
            $scope.intermediaryId = $scope.selectedShareClass.abnIntermdiaryId;
            $scope.intermediaryId = $scope.selectedShareClass.abnIntermediaryUuid;
          }
          if ($scope.proxyProvided === 'Y' && $scope.createNewProxy === 'Y') {
            $scope.attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $scope.gmId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $scope.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              intermediaryUuid: $scope.intermediaryUuid,
              attendanceId: null,
              isRegisterShareclass:
              $scope.selectedShareClass.isRegisterShareclass,
              isin: $scope.isin,
              initiatedThruShrhWrkst: 'Y',
              noOfSecurities: $scope.numberOfShares,
              intermediaryAdditionRequest: $scope.intRequestId,
              gmDate: $stateParams.gmDate,
              securityAccNo: $scope.securityAccNo,
              shareHolderVOList: [
                {
                  isSharePlanUser: 'N',
                  proxyProvided: $scope.proxyProvided,
                  createNewProxy: $scope.createNewProxy,
                  shareholderId: $sessionStorage.groupId,
                  shareclassId: $scope.selectedShareClass.shareclassId,
                  isin: $scope.isin,
                  isRegisterShareclass:
                  $scope.selectedShareClass.isRegisterShareclass,
                  proxyCity: $scope.proxyCity,
                  proxyCountry: $scope.isoCountrycode,
                  proxyInitials: $scope.proxyInitial,
                  proxyName: $scope.proxyName,
                  proxyType: $scope.proxyTypeCode,
                  proxyTitle: $scope.proxyTitle,
                  representativeName: $scope.representativeName,
                  legalIdentifier: $scope.legalIdentifier,
                },
              ],
            };
          } else if (
              $scope.proxyProvided === 'Y' &&
              $scope.createNewProxy === 'N'
          ) {
            $scope.attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $scope.gmId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $scope.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              intermediaryUuid: $scope.intermediaryUuid,
              attendanceId: null,
              isin: $scope.isin,
              isRegisterShareclass:
              $scope.selectedShareClass.isRegisterShareclass,
              initiatedThruShrhWrkst: 'Y',
              noOfSecurities: $scope.numberOfShares,
              intermediaryAdditionRequest: $scope.intRequestId,
              securityAccNo: $scope.securityAccNo,
              shareHolderVOList: [
                {
                  isSharePlanUser: 'N',
                  proxyProvided: $scope.proxyProvided,
                  createNewProxy: $scope.createNewProxy,
                  shareholderId: $sessionStorage.groupId,
                  shareclassId: $scope.selectedShareClass.shareclassId,
                  isin: $scope.isin,
                  proxyId: $scope.selectedProxyList[0].proxyId,
                  proxyType: $scope.selectedProxyList[0].proxyType
                },
              ],
            };
          } else {
            $scope.attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $scope.gmId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $scope.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              intermediaryUuid: $scope.intermediaryUuid,
              attendanceId: null,
              isin: $scope.isin,
              isRegisterShareclass:
              $scope.selectedShareClass.isRegisterShareclass,
              initiatedThruShrhWrkst: 'Y',
              noOfSecurities: $scope.numberOfShares,
              intermediaryAdditionRequest: $scope.intRequestId,
              gmDate: $stateParams.gmDate,
              securityAccNo: $scope.securityAccNo,
              shareHolderVOList: [
                {
                  isSharePlanUser: 'N',
                  proxyProvided: 'N',
                  createNewProxy: 'N',
                  shareholderId: $sessionStorage.groupId,
                  shareclassId: $scope.selectedShareClass.shareclassId,
                  isin: $scope.isin,
                },
              ],
            };
          }
        }
        if ($scope.userType === 'IN') {
          attendanceRequestService
          .saveAttendanceRequestIntermediary(
              $scope.attendanceRequestData,
              'secureToken'
          )
          .then(
              function (response) {
                $scope.loadingSpin = false;
                if ($stateParams.isLinkedMeeting) {
                  if ($scope.linkedMeetingData.length === 0) {
                    $scope.goToAttendanceReqMeetingInfo();
                  } else {
                    $scope.hasLinkedMeetingIN = true;
                    $scope.modalBody8 =
                        $filter('translate')('linkedMeeting_attend_label') +
                        ' ' +
                        $stateParams.linkedMeetingData[0].issuerName +
                        ' ' +
                        $stateParams.linkedMeetingData[0].meetingName +
                        ', ';
                  }
                } else {
                  $scope.getLinkedMeetingData();
                }
              },
              function (error) {
                $scope.loadingSpin = false;
                $scope.error = error.data.errors[0].errorMessage;
                //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
                if (
                    error.data.errors[0].errorCode === 'CBP_ERR_520' &&
                    ($scope.userType === 'SH' || $scope.userType === 'IN')
                ) {
                  $scope.EmailData = {
                    votingInstType: 'attend',
                    languageCode: 'EN',
                    workStationType: $scope.userType,
                    meetingId: $scope.gmId,
                    shareClassId: $scope.selectedShareClass.shareclassId,
                    intermediaryId: $scope.intermediaryId,
                    issuerId: $scope.issuerId,
                    loginUserId: $sessionStorage.userId,
                  };
                  voteInstructionService
                  .sendSharesExceededMail($scope.EmailData, 'secureToken')
                  .then(
                      function (response) {
                        $log.debug(response);
                      },
                      function (error) {
                        $log.debug(error);
                      }
                  );
                }
              }
          );
        } else {
          attendanceRequestService
          .saveAttendanceRequest($scope.attendanceRequestData, 'secureToken')
          .then(
              function (response) {
                $log.debug(response.data.message);
                $scope.loadingSpin = false;
                if (
                    $scope.userType === 'SH' &&
                    $scope.selectedShareClass.isRegisterShareclass
                ) {
                  $scope.shMyAttendList();
                } else if ($scope.userType === 'SH') {
                  var phoneNo = '';
                  var intermediaryName = '';
                  var intEmailAdress = '';
                  var identificationNumber =
                      response.data.intermediaryVO.identificationNumber;
                  $scope.emailNotificationVo = {
                    meetingId: $scope.gmId,
                    instructionId: response.data.intermediaryVO.requestId,
                    shareholderId: $sessionStorage.groupId,
                    instructionType: 'AR',
                    intermediaryId: $scope.intermediaryId,
                    issuerId: $stateParams.issuerId,
                    issuerName: $stateParams.issuerName,
                  };
                  // removing send email to intermediary code because this handled in service call
                  myVoteService
                  .getIntermediaryDetails(
                      response.data.intermediaryVO.identificationNumber,
                      'attend'
                  )
                  .query()
                  .$promise.then(
                      function (data) {
                        $scope.selectedIntermediaryName = data.intermediaryName;
                        phoneNo = data.intermediaryPhone;
                        intermediaryName = data.intermediaryName;
                        intEmailAdress = data.emailAddress;
                        $scope.modalHeader = $filter('translate')(
                            'label_confirmVI_registration_header'
                        );
                        $scope.modalFooter = '';
                        $scope.modalLeftButton = $filter('translate')(
                            'label_ok'
                        );
                        $scope.modalRightButton = '';
                        $scope.confirmButtonAction = function () {
                          $scope.shMyAttendList();
                        };
                        $scope.dismissButtonAction = function () {
                          // do nothing
                        };

                        if (data.agentAsIntermediay === 'Y') {
                          $scope.modalBody =
                              $filter('translate')(
                                  'callIntermediary_SorryModalbody1'
                              ) +
                              phoneNo +
                              $filter('translate')(
                                  'callIntermediary_SorryModalbody2'
                              );
                          $scope.modalBody1 = ' ';
                          $scope.modalBody2 = ' ';
                          $scope.modalBody3 = ' ';
                          $scope.modalBody4 = ' ';
                          $scope.modalBody5 = ' ';
                          $scope.modalBody6 = ' ';
                          $scope.modalBody7 = ' ';
                        } else {
                          $scope.modalBody = $filter('translate')(
                              'label_callIntermediary_AR_registration'
                          );
                          if (identificationNumber) {
                            $scope.modalBody1 =
                                $filter('translate')(
                                    'label_ask_provideIdentification_VI'
                                ) +
                                ' ' +
                                identificationNumber;
                          }
                          $scope.modalBody2 = $filter('translate')(
                              'label_sh_vi_modalBody2'
                          );
                          $scope.modalBody3 = $filter('translate')(
                              'label_sh_vi_modalBody3'
                          );
                          $scope.modalBody4 =
                              $filter('translate')('lable_contactDetails') +
                              $filter('translate')('label_colon');
                          if (intermediaryName) {
                            $scope.modalBody5 =
                                $filter('translate')('label_intermediary') +
                                $filter('translate')('label_colon') +
                                ' ' +
                                intermediaryName;
                          }
                          if (intEmailAdress) {
                            $scope.modalBody6 =
                                $filter('translate')('label_addresseeEmail') +
                                $filter('translate')('label_colon') +
                                ' ' +
                                intEmailAdress;
                          }
                          if (phoneNo) {
                            $scope.modalBody7 =
                                $filter('translate')('label_telephoneNumber') +
                                $filter('translate')('label_colon') +
                                ' ' +
                                phoneNo;
                          }
                          attendanceRequestService
                          .getLinkedMeetingList(
                              $scope.gmId,
                              $scope.selectedShareClass.shareclassId,
                              $scope.intermediaryId,
                              'AR',
                              $sessionStorage.selectedLang
                          )
                          .then(
                              function (response) {
                                if (response.data.linkedMeeting === 'Y') {
                                  $scope.linkedMeeting = true;
                                  $scope.linkedMeetingId =
                                      response.data.meetingId;
                                  $scope.linkedMeetingIssuerId =
                                      response.data.issuerId;
                                  $scope.linkedMeetingIssuerName =
                                      response.data.issuerName;
                                  $scope.modalLeftButton = $filter('translate')(
                                      'label_yes'
                                  );
                                  $scope.modalRightButton = $filter(
                                      'translate'
                                  )('label_no');
                                  $scope.modalBody8 =
                                      $filter('translate')(
                                          'linkedMeeting_attend_label'
                                      ) +
                                      ' ' +
                                      response.data.issuerName +
                                      ' ' +
                                      response.data.meetingName +
                                      ', ';

                                  $scope.confirmButtonAction = function () {
                                    $scope.goToLinkedMeetingSH();
                                  };
                                  $scope.dismissButtonAction = function () {
                                    $scope.shMyAttendList();
                                  };
                                }
                              },
                              function (error) {
                                $log.debug(error);
                              }
                          );
                        }
                        $scope.showPop12 = true;
                      },
                      function (error) {
                        $log.debug(error);
                      }
                  );
                } else {
                  $timeout(function () {
                    if ($scope.userType !== 'IN') {
                      if ($scope.userType === 'SP') {
                        var selectedIndex = 1;
                      } else {
                        selectedIndex = 0;
                      }
                      $state.go('shMyVotes', {
                        type: 'attend',
                        selectedIndex: selectedIndex,
                      });
                    } else {
                      document.getElementById('backButton').click();
                    }
                  }, 2000);
                }
              },
              function (error) {
                $scope.loadingSpin = false;
                $scope.errorCode = error.data.errors[0].errorCode;
                $scope.error = error.data.errors[0].errorMessage;
                //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
                if (
                    error.data.errors[0].errorCode === 'CBP_ERR_520' &&
                    $scope.userType === 'SH'
                ) {
                  $scope.EmailData = {
                    votingInstType: 'attend',
                    languageCode: $scope.languageCode,
                    workStationType: $scope.userType,
                    meetingId: $scope.gmId,
                    shareClassId: $scope.selectedShareClass.shareclassId,
                    shareholderId: $sessionStorage.groupId,
                    issuerId: $scope.issuerId,
                    loginUserId: $sessionStorage.userId,
                  };
                  voteInstructionService
                  .sendSharesExceededMail($scope.EmailData, 'secureToken')
                  .then(
                      function (response) {
                        $log.debug(response);
                      },
                      function (error) {
                        $log.debug(error);
                      }
                  );
                }
              }
          );
        }
      };

      $scope.getLinkedMeetingData = function() {
        $scope.loadingSpin = true;
        intermediaryService
        .getIntermediaryLinkedMeetings(
            $scope.attendanceRequestData.meetingId,
            $scope.selectedShareClass.shareclassId
        )
        .query()
        .$promise.then(
            function (data) {
              $scope.loadingSpin = false;
              if (data.length > 0) {
                $scope.linkedMeetingData = data;
                $scope.hasLinkedMeetingIN = true;
                $scope.modalBody =
                    $filter('translate')('linkedMeeting_attend_label') +
                    ' ' +
                    data[0].issuerName +
                    ' ' +
                    data[0].meetingName +
                    ', ' +
                    $filter('translate')('label_plsClick_Yes_toContinue');
              } else {
                $scope.goToAttendanceReqMeetingInfo();
              }
            },
            function (error) {
              $scope.loadingSpin = false;
            }
        );
      };


      $scope.checkParticularCases();

      if (
        $sessionStorage.usertype === 'SH' &&
        $stateParams.attendanceDataDeatils != null &&
        $stateParams.attendanceDataDeatils.sharePlanHolderVO != null
      ) {
        $scope.securityAccNo =
          $stateParams.attendanceDataDeatils.sharePlanHolderVO.securityAccountNumber;
        // legal shareholder have to permit only natural person as proxy
        if($scope.attendanceData.sharePlanHolderVO.shareHolderType === 'LGL') {
          $scope.attendanceData.proxyTypeVO = $filter('filter')(
              $scope.attendanceData.proxyTypeVO, {proxyTypeCode: 'NAT'}, true);
        }
      }
      var defautProxy = {
        proxyCity: 'Den Haag',
        proxyCountry: 'NLD',
        proxyInitial: null,
        proxyName: 'Elke Vertegenwoordiger Van De VEB',
        proxyType: 'LGL',
        proxyTitle: null,
        proxyId: null,
        representativeName: null,
        legalIdentifier: null,
        proxyProvided: null,
        createNewProxy: null,
      };
      //Below code is added for ForFarmer default proxy chnages
      var proxyForFarmers = {
        proxyCity: 'Lochem',
        proxyCountry: 'NLD',
        proxyInitial: null,
        proxyName: 'Coöperatie FromFarmers UA',
        proxyType: 'LGL',
        proxyTitle: null,
        proxyId: null,
        representativeName:
          'Iedere vertegenwoordiger van de Cöoperatie FromFarmers',
        legalIdentifier: null,
        proxyProvided: null,
        createNewProxy: null,
      };
      $scope.getParticipationMethods = () => {
        participationMethodService.getParticipationMethods(
            $scope.gmId, $scope.selectedShareClassId)
        .then(
            function (response) {
              $scope.allowedParticipationMethodForShareClass = response.data.participationMethods;
              $scope.isVotingEnabled = $scope.allowedParticipationMethodForShareClass.includes(
                  'PRXY') || $scope.allowedParticipationMethodForShareClass.includes(
                  'EVOT') ;
              }
            ),
            function (error) {
              $log.debug(error);
            };
      };
      //Below is the function call to fetch the linked intermediaries for a proxy intermediary and populate the select intermediary dropdown. - SFCBP-2488
      $scope.fetchLinkedIntermediaries = function () {
        if ($sessionStorage.proxyType === 'P') {
          $scope.prxoyIntermediaryFlag = 'Y';
          voteInstructionService
            .getLinkedIntermediaries($sessionStorage.groupId)
            .query()
            .$promise.then(
              function (data) {
                $scope.linkedIntermediaryList = data;
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      };
      //Call the below function on page load  - SFCBP-2488
      $scope.fetchLinkedIntermediaries();
      // Below is the function used to fetch issuer logo
      userGroupService.getIssuerGroupLogo($scope.issuerId).then(
        function (response) {
          $scope.issuerGroupDetails = response.data;
        },
        function (error) {
          $log.debug(error);
        }
      );
      $scope.handleMeetings();

      $scope.goToDetailsPage = function () {
        $state.go('generalMeetings', {
          selectedIndex: 0,
          meetingId: $sessionStorage.gmId,
        });
      };

      $scope.getAttendanceProxyList = function () {
        attendanceRequestService
          .getAvailableProxyList($sessionStorage.groupId)
          .then(
              $scope.attandanceProxyCallback(),
            function (error) {
              $log.debug(error);
            }
          );
      };

      $scope.getAttendanceProxyList();
      $scope.languageCode = $scope.languageCode || 'EN';
      LanguagePreferenceService.register(this);

      /*
       * Note: the $scope.profileDetails contains all details needed for the profileForm in the DOM.
       * The profileDetails are initially set by the getProxy method when a search on securities
       * account number is performed and a shareHolderVO is returned by the BE.
       * To load all shareholder details in the DOM of the page of the next linked meeting,
       * the persisted shareholderVO is passed as a stateParam to the next UIRouter state to again
       * set the $scope.profileDetails. The persisted shareholderVO is an attribute of the AttendanceVO,
       * which is the data transfer object used for persistence of the attendance request.
       */
      function importLinkedMeetingParamsIntoScope() {
        $stateParams.linkedMeetingData.shift();
        $scope.isLinkedMeeting = $stateParams.isLinkedMeeting;
        $scope.linkedMeetingData = $stateParams.linkedMeetingData;
        $scope.registrationList = $stateParams.shareholderRegistrationDetailVO;
        $scope.profileDetails = $stateParams.shareHolderVOList[0];
        $scope.securityAccountNumber =
          $scope.profileDetails.securityAccountNumber;
        $scope.profileListData = $stateParams.profileListData;
        $scope.shTitleRequired = $stateParams.shTitleRequired;
        $scope.selectedShareClass = $stateParams.selectedShareClass;
        if ($stateParams.attendProxyBy) {
          $scope.attendProxyBy = $stateParams.attendProxyBy;
        }
        $scope.breadCrumbDetails = $stateParams.breadCrumbDetails;
        fillShareholderProfile();
      }

      this.invoke = function (lang) {
        var selectedLang = $filter('uppercase')(lang);
        $scope.languageCode = selectedLang;
        $scope.languageSelected = selectedLang;
        attendanceRequestService
          .getAttendanceRequestView(
            $stateParams.gmId,
            $scope.languageCode,
            $stateParams.sharePlanId,
            $sessionStorage.usertype
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.attendanceData = data;
              $scope.securityAccNo =
                data.sharePlanHolderVO.securityAccountNumber;
              $scope.getAttendanceProxyList();
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      // ALM - 10528 autopopulating field when 'veb' is keyed in for name filed for legal person
      $scope.autoPopulate = function () {
        if (
          $scope.shareHolderType[0].proxyTypeCode === 'LGL' &&
          ($scope.profileDetails.lastName.toLowerCase() === 'veb' ||
            $scope.profileDetails.lastName
              .toLowerCase()
              .indexOf('vereniging van effectenbezitters') > -1)
        ) {
          $scope.profileDetails.address = 'Amaliastraat 7';
          $scope.profileDetails.emailAddress = 'info@veb.net';
          $scope.profileDetails.city = 'Den Haag';
          $scope.selectedCountry = $filter('filter')(
            $scope.attendanceData.countryCodeVO,
            {
              isoCountryCode: 'NLD',
            },
            true
          );
          $scope.attendProxyBy = 'proxySelect';
        }
      };
      //Following is the method to populate the modal window for logout and redirect to particular intermediary's website
      $scope.logoutAndRedirectModal = function (intermediaryName) {
        $scope.modalRightButton =
          $filter('translate')('label_proceedToLogout') + intermediaryName;
        $scope.modalBody =
          $filter('translate')('label_asACustomerOf') +
          intermediaryName +
          $filter('translate')('label_voteViaIntermediary') +
          intermediaryName;
        $scope.showLogoutAndRedirectId = true;
      };

      // Below is the function call on selecting of intermediary in share holder work station to get intermediary Id
      $scope.onintermediaryChange = function (selectedIntermediary) {
        $scope.selectedIntermediaryFlag = false;
        $scope.intermediaryId = selectedIntermediary.intermediaryId;
        $scope.intermediaryUuid = selectedIntermediary.intermediaryUuid;
        if ($sessionStorage.usertype !== 'IN') {
          attendanceRequestService
            .getProxyList(
              $scope.gmId,
              null,
              $scope.selectedShareClassId,
              $sessionStorage.groupId,
              $scope.userType,
              $scope.intermediaryId
            )
            .query()
            .$promise.then(
              function (data) {
                $scope.proxyData = data.shareholderRegistrationDetailVO[0];
                if ($scope.proxyData) {
                  angular.forEach($scope.proxyData.proxyVOList, function (
                    item
                  ) {
                    if (
                      item.proxyName === 'Elke Vertegenwoordiger Van De VEB'
                    ) {
                      $scope.proxyDefaultDetails = true;
                    }
                  });
                }
                if ($scope.proxyDefaultDetails) {
                  $scope.proxyDetails = $scope.proxyData.proxyVOList;
                }
              },
              function (error) {
                $log.debug(error);
              }
            );
        }

        if (
          selectedIntermediary.intermediaryName ===
            appConstant.INTERMEDIARY_BINCKBANK ||
          selectedIntermediary.intermediaryName ===
            appConstant.INTERMEDIARY_ALEX
        ) {
          $scope.selectedIntermediary.intermediaryName =
            selectedIntermediary.intermediaryName;
          $scope.logoutAndRedirectModal(selectedIntermediary.intermediaryName);
        }
      };
      if (
        $scope.selectedShareClass &&
        $scope.selectedShareClass.intermediaryVO.length === 1 &&
        $sessionStorage.usertype !== 'IN'
      ) {
        $scope.intermediaryId =
          $scope.selectedShareClass.intermediaryVO[0].intermediaryId;
        $scope.intermediaryUuid =
            $scope.selectedShareClass.intermediaryVO[0].intermediaryUuid;
        attendanceRequestService
          .getProxyList(
            $scope.gmId,
            null,
            $scope.selectedShareClassId,
            $sessionStorage.groupId,
            $scope.userType,
            $scope.intermediaryId
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.proxyData = data.shareholderRegistrationDetailVO[0];
              if ($scope.proxyData) {
                angular.forEach($scope.proxyData.proxyVOList, function (item) {
                  if (item.proxyName === 'Elke Vertegenwoordiger Van De VEB') {
                    $scope.proxyDefaultDetails = true;
                  }
                });
              }
              if ($scope.proxyDefaultDetails) {
                $scope.proxyDetails = $scope.proxyData.proxyVOList;
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      }
      /*
       * Below is the function call on selecting of share-class to get number of shares in share-plan work station,get
       * ISIN and to get proxy related data in share-plan and share-holder workstation
       */
      $scope.onShareClassChange = function (selectedShareClass) {
        $scope.reloadCancel = false;
        $scope.reloadCancelMsg = '';
        if (!selectedShareClass) {
          selectedShareClass = $scope.selectedShareClass;
        }
        $scope.isin = selectedShareClass.isin;
        $scope.selectedShareClassId = selectedShareClass.shareclassId;
        $scope.getParticipationMethods();
        if ($scope.userType === 'SP') {
          $scope.numberOfShares = selectedShareClass.shareholderPosition;
          var selectedIntermediary = {
            intermediaryId: null,
          };
          $scope.onintermediaryChange(selectedIntermediary);
        }
        /**
         * The following code is an important one. For shareholder ws, during creation of AR, if the
         * selected security class has more than one intermediary, then a dropdown needs to be shown.
         * Else, no dropdown. Also, when there is more than one intermediary (All intermediaries) and the shareholder
         * has selected preferred intermediary while registration, then the preferred intermediary should
         * be selected by default. If the agent has created GM with shareclass having only one intermediary, then it
         * takes precedence over the shareholder preference.
         */
        if (
          selectedShareClass &&
          selectedShareClass.intermediaryVO &&
          $sessionStorage.usertype === 'SH'
        ) {
          if (selectedShareClass.intermediaryVO.length <= 1) {
            $scope.morethanOneIntermediary = false;
            $scope.oneIntermediary = true;
            $scope.selectedIntermediary = selectedShareClass.intermediaryVO[0];
            $scope.onintermediaryChange(selectedShareClass.intermediaryVO[0]);
          } else {
            $scope.morethanOneIntermediary = true;
            $scope.oneIntermediary = false;
            // Below code is added for default selection of Intermediary and share class for linked meeting
            if (selectedIntermediaryName && linkedISIN) {
              $scope.selectedIntermediary = $filter('filter')(
                selectedShareClass.intermediaryVO,
                {
                  intermediaryName: selectedIntermediaryName,
                },
                true
              )[0];
              $scope.selectedIntermediaryFlag = true;
            } else {
              $scope.selectedIntermediary =
                selectedShareClass.intermediaryVO[0];
            }
          }
        }
        $scope.availableIntermediaries = [];
        if (
          $scope.morethanOneIntermediary === true &&
          $scope.oneIntermediary === false
        ) {
          angular.forEach(selectedShareClass.intermediaryVO, function (key2) {
            $scope.availableIntermediaries.push(key2);
            // Below code is added for default selection of Intermediary
            //and share class for linked meeting SFCBP-2725
            //!linkedISIN && !selectedIntermediaryName -  added this condition to prevent going inside that if condition when any one value is there
            if (
              selectedIntermediaryName &&
              linkedISIN &&
              $scope.userType === 'SH'
            ) {
              if (selectedIntermediaryName) {
                $scope.selectedIntermediary = $filter('filter')(
                  selectedShareClass.intermediaryVO,
                  {
                    intermediaryName: selectedIntermediaryName,
                  },
                  true
                )[0];
                selectedIntermediaryName = '';
                //SFCBP-2910 - this flag is added to avoid submitting AR to ABN AMRO intermediary by default
                // in linked meeting scenario
                $scope.selectedIntermediaryFlag = true;
              }
            } else if (
              $scope.availableIntermediaries.length === 1 &&
              !$scope.intermediaryPresent &&
              !linkedISIN &&
              !selectedIntermediaryName
            ) {
              $scope.selectedIntermediary = $scope.availableIntermediaries[0];
              $scope.selectedIntermediaryFlag = true;
            } else if (
              selectedShareClass.intermediaryIdWoSalt ===
                key2.intermediaryIdWoSalt &&
              !linkedISIN &&
              !selectedIntermediaryName
            ) {
              $scope.selectedIntermediary = key2;
              $scope.selectedIntermediaryFlag = true;
            }
          });
        }

        if (
          $sessionStorage.usertype === 'SH' &&
          ($scope.selectedIntermediary.intermediaryName ===
            appConstant.INTERMEDIARY_BINCKBANK ||
            $scope.selectedIntermediary.intermediaryName ===
              appConstant.INTERMEDIARY_ALEX)
        ) {
          $timeout(function () {
            // timeout is needed so that it gets executed after the template is rendered
            $scope.logoutAndRedirectModal(
              $scope.selectedIntermediary.intermediaryName
            );
          });
        }
      };

      // below check is for showing/hiding dropdown based on number of share classes
      if ($scope.attendanceData.shareClassISINVO.length === 1) {
        $scope.moreThanOneShareClass = false;
        $scope.selectedShareClass = $scope.attendanceData.shareClassISINVO[0];
        $scope.onShareClassChange($scope.selectedShareClass);
      } else {
        $scope.moreThanOneShareClass = true;
        // Below code is added for default selection of Intermediary
        //and share class for linked meeting SFCBP-2725
        if (
          linkedISIN &&
          selectedIntermediaryName &&
          $scope.userType === 'SH'
        ) {
          $scope.selectedShareClass = $filter('filter')(
            $scope.attendanceData.shareClassISINVO,
            {
              isin: linkedISIN,
            },
            true
          )[0];
          if ($scope.selectedShareClass) {
            $scope.selectedIntermediary = $filter('filter')(
              $scope.selectedShareClass.intermediaryVO,
              {
                intermediaryName: selectedIntermediaryName,
              },
              true
            )[0];
          }
        }
      }

      /* date picker */
      $scope.inlineOptions = {
        customClass: getDayClass,
      };
      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.popup1 = {
        opened: false,
      };

      function disabled(data) {
        var date, mode;
        date = data.date;
        mode = data.mode;
        $log.debug(date);
        $log.debug(mode);
      }

      $scope.dateOptions = {
        dateDisabled: disabled,
        formatYear: 'yy',
        maxDate: new Date(),
        //removed min validation for date picker -SFCBP-1977
        showWeeks: false,
      };

      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );
            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }

      $scope.dateEnter = function (dob) {
        var currentdate = new Date().setHours(0, 0, 0, 0);
        if (dob > currentdate) {
          $scope.dobError = true;
        } else {
          $scope.dobError = false;
        }
      };
      /* date picker end */

      /*
       * Below is the function to check whether the entered Securities account number is already present or not and
       * get proxy related data in intermediary workstations
       */
      $scope.getProxy = function (securityAccountNumber, selectedShareClass) {
        $scope.loading = true;
        attendanceRequestService
          .getProxyList(
            $scope.gmId,
            securityAccountNumber,
            selectedShareClass.shareclassId,
            '0',
            $scope.userType,
            $sessionStorage.groupId
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.errorMessage = null;
              $scope.proxyData = data;
              if ($scope.proxyData) {
                angular.forEach($scope.proxyData.proxyVOList, function (item) {
                  if (item.proxyName === 'Elke Vertegenwoordiger Van De VEB') {
                    $scope.proxyDefaultDetails = true;
                  }
                });
                if ($scope.proxyData.shareHolderVO) {
                  if (
                    'NAT' === $scope.proxyData.shareHolderVO.shareHolderType
                  ) {
                    $scope.shTitleRequired = true;
                  } else {
                    $scope.shTitleRequired = false;
                  }
                }
              }
              if ($scope.proxyDefaultDetails) {
                $scope.proxyDetails = $scope.proxyData.proxyVOList;
              }
              $scope.profileDetails = $scope.proxyData.shareHolderVO;
              fillShareholderProfile();

              $scope.loading = false;
            },
            function (error) {
              $scope.loading = false;
              $scope.errorMessage = error.data.errors[0].errorMessage;
              $scope.profileForm.$setPristine();
              $scope.profileDetails = '';
              $scope.shareHolderType[0] = null;
              $scope.selectedCountry[0] = null;
              $scope.shareHolderVO.selectedTitle[0] = null;
              $scope.selectedUserType[0] = null;
            }
          );
      };

      function fillShareholderProfile() {
        if ($scope.profileDetails) {
          $scope.shareHolderVO.initials = $scope.profileDetails.initials;
        }
        $scope.shareHolderType = $filter('filter')(
          $scope.attendanceData.proxyTypeVO,
          {
            proxyTypeCode: $scope.profileDetails.shareHolderType,
          },
          true
        );
        $scope.selectedCountry = $filter('filter')(
          $scope.attendanceData.countryCodeVO,
          {
            isoCountryCode: $scope.profileDetails.country,
          },
          true
        );
        $scope.shareHolderVO.selectedTitle = $filter('filter')(
          $scope.attendanceData.shareHolderTitleVO,
          {
            title: $scope.profileDetails.title,
          },
          true
        );
        $scope.selectedUserType = $filter('filter')(
          $scope.attendanceData.owneTypeVO,
          {
            userType: $scope.profileDetails.owneType,
          },
          true
        );
      }

      $scope.onProxyNameChange = function (selectedProxyList) {
        //farmerProxySelected - is added for ForFarmer default proxy chnages
        farmerProxySelected = false;
        if (
          $scope.selectedProxyList[0].proxyName === 'Coöperatie FromFarmers UA'
        ) {
          farmerProxySelected = true;
        }
        $scope.selectedProxyInitial = selectedProxyList[0].proxyInitial;
        $scope.selectedProxyCountry = $filter('filter')(
          $scope.attendanceData.countryCodeVO,
          {
            isoCountryCode: selectedProxyList[0].proxyCountry,
          },
          true
        );
        $scope.selectedProxyRepresentativeName =
          selectedProxyList[0].representativeName;
        $scope.selectedProxyLegalIdentifier =
          selectedProxyList[0].legalIdentifier;
        $scope.selectedProxyCity = selectedProxyList[0].proxyCity;
        $scope.selectedProxyTitle = $filter('filter')(
          $scope.attendanceData.shareHolderTitleVO,
          {
            title: selectedProxyList[0].proxyTitle,
          },
          true
        );
        $scope.selectedProxyCode = $filter('filter')(
          $scope.attendanceData.proxyTypeVO,
          {
            proxyTypeCode: selectedProxyList[0].proxyType,
          },
          true
        );
      };
      // Below is the function call on selection radio buttons to hide and show particular section

      $scope.$watch('attendProxyBy', function (value) {
        if (value === 'proxyCreate') {
          clearProxyDetails();
          $scope.createProxy = true;
          $scope.selectProxy = false;
          $scope.proxyProvided = 'Y';
          $scope.createNewProxy = 'Y';
          //To select country by default
          $scope.selectedProxyCountry = $filter('filter')(
            $scope.attendanceData.countryCodeVO,
            {
              isoCountryCode: 'NLD',
            },
            true
          )[0];
        } else if (value === 'proxySelect') {
          $scope.createProxy = false;
          $scope.selectProxy = true;
          $scope.proxyProvided = 'Y';
          $scope.createNewProxy = 'N';
          //farmerProxySelected - is added for ForFarmer default proxy chnages
          farmerProxySelected = false;
          //Below changes are added to remove default proxy value for shareholder WS
          if ($sessionStorage.usertype === 'IN' && !$scope.isForFarmer) {
            $scope.selectedProxyList = $filter('filter')(
              $scope.proxyDetails,
              {
                proxyName: 'Elke Vertegenwoordiger Van De VEB',
              },
              true
            );
          } else if ($scope.isForFarmer) {
            farmerProxySelected = true;
            $scope.selectedProxyList = $filter('filter')(
              $scope.proxyDetails,
              {
                proxyName: 'Coöperatie FromFarmers UA',
              },
              true
            );
            $scope.onProxyNameChange($scope.selectedProxyList);
          } else {
            $scope.selectedProxyList = [$scope.proxyDetails[0]];
            $scope.onProxyNameChange($scope.proxyDetails);
          }
        } else {
          $scope.createProxy = false;
          $scope.selectProxy = false;
          $scope.proxyProvided = 'N';
          $scope.createNewProxy = 'N';
        }
        if (
          $stateParams.isLinkedMeeting &&
          !$scope.attendProxyBySecundarySelection
        ) {
          if ($scope.attendProxyBy !== 'none') {
            $scope.attendProxyBySecundarySelection = true;
          }
          fillProxyDetailsLinkedMeeting();
        }
      });

      let clearProxyDetails = function () {
        $scope.proxyCity = null;
        $scope.proxyInitial = null;
        $scope.selectedProxyType = null;
        $scope.selectedProxyTitle = null;
        $scope.proxyTypeCode = null;
        $scope.proxyName = null;
        $scope.proxyTitle = null;
        $scope.representativeName = null;
        $scope.legalIdentifier = null;
      };

      let fillProxyDetailsLinkedMeeting = function () {
        $scope.createNewProxy =
          $stateParams.shareHolderVOList[0].createNewProxy;
        $scope.proxyCity = $stateParams.shareHolderVOList[0].proxyCity;
        $scope.proxyInitial = $stateParams.shareHolderVOList[0].proxyInitials;
        $scope.selectedProxyType = $stateParams.selectedProxyType;
        $scope.selectedProxyTitle = $stateParams.selectedProxyTitle;
        $scope.selectedProxyCountry = $stateParams.selectedProxyCountry;
        $scope.proxyTypeCode = $stateParams.shareHolderVOList[0].proxyType;
        $scope.proxyName = $stateParams.shareHolderVOList[0].proxyName;
        $scope.proxyTitle = $stateParams.shareHolderVOList[0].proxyTitle;
        $scope.representativeName =
          $stateParams.shareHolderVOList[0].representativeName;
        $scope.legalIdentifier =
          $stateParams.shareHolderVOList[0].legalIdentifier;
      };

      // Below function is to disable intermediary on click of
      // add intermediary
      $scope.intermediary = function () {
        $scope.hideInteDropDown = true;
        if (!$scope.disableIntermediary) {
          $scope.disableIntermediary = true;
          $scope.selectedIntermediaryFlag = '';
        } else {
          $scope.disableIntermediary = false;
          $scope.hideInteDropDown = false;
        }
      };
      // Below function is to disable intermediary on click of cancel add intermediary
      $scope.closeIntermediary = function () {
        $scope.hideInteDropDown = false;
        if (!$scope.disableIntermediary) {
          $scope.disableIntermediary = true;
          $scope.selectedIntermediaryFlag = '';
        } else {
          $scope.disableIntermediary = false;
        }
      };
      // Below is the function to send add intermediary request in share holder workstation

      $scope.addIntermediary = function () {
        $scope.addIntermediaryFlag = false;
        $scope.intermediary = $filter('filter')(
          $scope.selectedShareClass.intermediaryVO,
          {
            intermediaryName: 'ABN AMRO Registrar',
          },
          true
        );
        $scope.intermediaryId = $scope.selectedShareClass.abnIntermdiaryId;
        $scope.intermediaryUuid = $scope.selectedShareClass.abnIntermediaryUuid;
        var documentFormData = new FormData();
        documentFormData.append(
          'country',
          $scope.selectedIntemediaryCountry.isoCountryCode
        );
        documentFormData.append('intermediaryId', $scope.intermediaryId);
        documentFormData.append('intermediaryName', $scope.intermediaryName);
        documentFormData.append('phoneNo', $scope.phoneNo);
        documentFormData.append('emailAddress', $scope.emailAddress);
        if ($scope.poaFormFile) {
          $scope.fileName = $scope.poaFormFile.name;
          documentFormData.append('proofOfEntitlement', $scope.poaFormFile);
          documentFormData.append(
            'proofOfEntitlementFileName',
            $scope.fileName
          );
        }
        documentFormData.append('userId', $sessionStorage.userId);
        documentFormData.append('meetingId', $scope.gmId);
        documentFormData.append('userName', $sessionStorage.userName);
        documentFormData.append('langCode', $scope.languageCode);
        documentFormData.append('requestType', 'attendance request');
        var file = $scope.poaFormFile;
        var uploadUrl = 'v1/shareholder/addIntermediaryRequest';
        fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
          function (data) {
            if (
              data.status === 500 &&
              data.data.errors[0].errorCode === 'CBP_ERR_407'
            ) {
              $scope.message = data.data.errors[0].errorMessage;
            } else {
              $scope.message = data.data.message;
              $scope.intRequestId = data.data.requestId;
            }
            if (data.status === 200) {
              $scope.showIntermediary = false;
              $scope.selectedIntermediaryFlag = true;
              $scope.disableAddSection = true;
              $scope.addIntermediaryFlag = true;
            } else {
              $scope.showIntermediary = true;
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      $scope.changeUserType = function () {
        $scope.shareHolderType = [];
        $scope.shareHolderType[0] = $scope.attendanceData.proxyTypeVO[0];
        $scope.profileDetails.owneType = $scope.selectedUserType[0].userType;
        if (
          angular.equals(
            $scope.attendanceData.proxyTypeVO[0].proxyTypeCode,
            'LGL'
          )
        ) {
          $scope.attendProxyBy = 'proxySelect';
          $scope.createProxy = false;
          $scope.selectProxy = true;
          $scope.proxyProvided = 'Y';
          $scope.createNewProxy = 'Y';
          //farmerProxySelected and $scope.isForFarmer - is added for ForFarmer default proxy chnages
          farmerProxySelected = false;
          if ($scope.isForFarmer) {
            farmerProxySelected = true;
            $scope.proxyCity = 'Lochem';
            $scope.isoCountrycode = 'NLD';
            $scope.proxyName = 'Coöperatie FromFarmers UA';
            $scope.representativeName =
              'Iedere vertegenwoordiger van de Cöoperatie FromFarmers';
            $scope.legalEntityIdentifier = null;
          } else {
            $scope.proxyName = 'Elke Vertegenwoordiger Van De VEB';
            $scope.proxyCity = 'Den Haag';
            $scope.isoCountrycode = 'NLD';
          }
        } else {
          $scope.attendProxyBy = 'none';
          $scope.createProxy = false;
          $scope.selectProxy = false;
          $scope.proxyProvided = 'N';
          $scope.createNewProxy = 'N';
        }
      };
      $scope.selectedProxy = function () {
        if ($scope.selectedProxyType.proxyTypeCode === 'LGL') {
          $scope.titleRequired = false;
        } else {
          $scope.titleRequired = true;
        }
      };
      $scope.selectedSHType = function () {
        if ($scope.shareHolderType[0].proxyTypeCode === 'LGL') {
          $scope.attendProxyBy = 'proxySelect';
          $scope.shTitleRequired = false;
          $scope.createProxy = false;
          $scope.selectProxy = true;
          $scope.proxyProvided = 'Y';
          $scope.createNewProxy = 'Y';
          //farmerProxySelected and $scope.isForFarmer - is added for ForFarmer default proxy chnages
          farmerProxySelected = false;
          if ($scope.isForFarmer) {
            farmerProxySelected = true;
            $scope.proxyCity = 'Lochem';
            $scope.isoCountrycode = 'NLD';
            $scope.proxyName = 'Coöperatie FromFarmers UA';
            $scope.representativeName =
              'Iedere vertegenwoordiger van de Cöoperatie FromFarmers';
            $scope.legalEntityIdentifier = null;
          } else {
            $scope.proxyName = 'Elke Vertegenwoordiger Van De VEB';
            $scope.proxyCity = 'Den Haag';
            $scope.isoCountrycode = 'NLD';
          }
        } else {
          $scope.attendProxyBy = 'none';
          $scope.shTitleRequired = true;
          $scope.createProxy = false;
          $scope.selectProxy = false;
          $scope.proxyProvided = 'N';
          $scope.createNewProxy = 'N';
        }
      };
      $scope.onTitleChange = function (title) {
        $scope.shTitleRequired = true;
        $log.debug(title);
      };
      $scope.shMyAttendList = function () {
        $state.go('shMyVotes', {
          type: 'attend',
          selectedIndex: 0,
          landingThrough: 'addAttend',
        });
      };

      $scope.termsOfUseUrl = getTermsOfUseUrl(
        'GM',
        $sessionStorage.workstationData.selectedLang
      );

      // Below method call from HTML on click of upload share plan data.
      $scope.onFileSelect = function (e) {
        $scope.loadingFileSpin = true;
        $scope.fileStatus = '';
        var filesList = e.files;
        var fd = new FormData();
        fd.append('file', filesList[0]);
        $scope.fileType = filesList[0].type;
        voteInstructionService.uploadBOFile(fd, 'secureToken').then(
          function (response) {
            $scope.loadingFileSpin = false;
            if (response.status === 200) {
              $scope.saveDisabled = false;
              $scope.filePath = response.data.filePath;
              $scope.fileStatus = response.data.fileStatus;
              $scope.fileName = response.data.fileName;
              $scope.saveStatus = '';
              $scope.errorHere = false;
            } else if (response.data.errors) {
              if (response.data.errors[0].errorCode === 'CBP_ERR_513') {
                $scope.errorHere = true;
                $scope.errorMsg = response.data.errors[0].errorMessage;
                $scope.saveDisabled = true;
                $scope.fileName = response.data.fileName;
                $scope.fileStatus = response.data.fileStatus;
                $log.debug('error');
                $scope.saveStatus = '';
              }
            } else {
              $scope.errorHere = true;
              $scope.errorMsg = response.data.fileStatus;
              $scope.saveDisabled = true;
              $scope.fileName = response.data.fileName;
              $scope.fileStatus = response.data.fileStatus;
              $scope.saveStatus = '';
            }
          },
          function (error) {
            $scope.loadingFileSpin = false;
            $scope.errorHere = true;
            $scope.errorMsg = $filter('translate')('label_file_format_xlsx');
            $scope.saveDisabled = true;
            $scope.fileName = '';
            $scope.fileStatus = error.fileStatus;
            $scope.saveStatus = '';
          }
        );
      };
      $scope.continueAttend = function () {
        $scope.showTcModal = true;
      };
      $scope.checkMaxAllowedAR = function () {
        attendanceRequestService
          .getAttendRequestCount(
            $scope.gmId,
            $scope.selectedShareClassId,
            $scope.languageSelected,
            $scope.userType
          )
          .then(
            function (response) {
              $scope.limitExceededObj = response.data;
              if (!$scope.limitExceededObj.limitExceeded) {
                $scope.submitAttendanceRequest();
              } else {
                $scope.modalBody = $scope.limitExceededObj.shLimit;
                $scope.showRequestNotAllowed = true;
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      $scope.checkShareclassModal = function () {
        attendanceRequestService
          .getAttendRequestCount(
            $scope.gmId,
            $scope.selectedShareClassId,
            $scope.languageSelected,
            $scope.userType
          )
          .then(
            function (response) {
              $scope.limitExceededObj = response.data;
              if (!$scope.limitExceededObj.limitExceeded) {
                $scope.intermediaryConfirmAR();
              } else {
                $scope.modalBody = $scope.limitExceededObj.intermediaryLimit;
                $scope.showShareClassAlert = true;
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };

      //Confirm the AR submission in intermediary ws
      $scope.intermediaryConfirmAR = function () {
        $scope.duplicateRequest = false;
        var proxySelectedName = null;
        if (
          $scope.attendProxyBy &&
          $scope.attendProxyBy !== 'none' &&
          $scope.proxyName
        ) {
          proxySelectedName = $scope.proxyName;
        } else if (
          $scope.attendProxyBy &&
          $scope.attendProxyBy !== 'none' &&
          $scope.selectedProxyList[0].proxyName
        ) {
          proxySelectedName = $scope.selectedProxyList[0].proxyName;
        }
        //Check if selected linked intermediary is Expired or Non Expired. For expired , throw error message
        if (
          $sessionStorage.proxyType === 'P' &&
          $scope.selectedLinkedInt.invalidPOAcandidates === 'Y'
        ) {
          $scope.linkedIntError = $filter('translate')('label_linkedIntError');
        } else if (
          $scope.selectedUserType[0].userType === 'NOMI' ||
          $scope.selectedUserType[0].userType === 'TRUS'
        ) {
          if ($scope.filePath === null || $scope.filePath === undefined) {
            $scope.errorHere = true;
            $scope.errorMsg = $filter('translate')('uploadFile_error');
          } else {
            $scope.errorHere = false;
            if (
              $scope.fileType !==
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
              $scope.errorHere = true;
              $scope.errorMsg = $filter('translate')('label_file_format_xlsx');
            } else {
              $scope.errorHere = false;
              // Service call to check whether the attendance request is already submitted for the current
              // combination of share class, intermediary and number of shares
              voteInstructionService
                .checkAttendRegistrationList(
                  $scope.gmId,
                  $scope.selectedShareClass.shareclassId,
                  $scope.profileDetails.lastName,
                  $scope.numberOfShares,
                  proxySelectedName,
                  0
                )
                .then(
                  function (response) {
                    if (response.data.length > 0) {
                      $scope.duplicateRequest = true;
                      $scope.modalBody =
                        'There is already an attendance request registered for ' +
                        $scope.profileDetails.lastName +
                        ' for ' +
                        $scope.numberOfShares +
                        ' securities. Are you sure you want to continue as this might be a duplicate entry?';
                      $scope.showAlertBox = true;
                    } else {
                      $scope.continueAttend();
                    }
                  },
                  function (error) {
                    $log.debug(error);
                  }
                );
            }
          }
        } else {
          $scope.errorHere = false;
          // Service call to check whether the voting instruction is already submitted for the current
          // combination of share class, intermediary and number of shares
          voteInstructionService
            .checkAttendRegistrationList(
              $scope.gmId,
              $scope.selectedShareClass.shareclassId,
              $scope.profileDetails.lastName,
              $scope.numberOfShares,
              proxySelectedName,
              0
            )
            .then(
              function (response) {
                if (response.data.length > 0) {
                  $scope.duplicateRequest = true;
                  $scope.modalBody =
                    'There is already an attendance request for ' +
                    $scope.profileDetails.lastName +
                    ' for ' +
                    $scope.numberOfShares +
                    ' securities. Are you sure you want to continue as this might be a duplicate entry?';
                  $scope.showAlertBox = true;
                } else {
                  $scope.continueAttend();
                }
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      };
      //below method is used to reload the page when click of cancel from logout and redirect modal window
      $scope.reload = function () {
        if ($scope.oneIntermediary === true) {
          $scope.reloadCancel = true;
          $scope.reloadCancelMsg = $filter('translate')(
            'label_reloadCancelError'
          );
        } else {
          $scope.selectedIntermediary = $scope.availableIntermediaries[0];
          $scope.onintermediaryChange($scope.selectedIntermediary);
        }
      };
      //Below method will logout and redirect the user to particular intermediary websites
      $scope.logoutAndRedirect = function () {
        /**
         * Below function is for logout process and reset the
         * session storage and workstation related data
         * and navigates to the login page
         */
        $scope.logout = function (reloadUrl, website) {
          AuthService.logout().then(function (data) {
            if (data.status === 200) {
              $timeout(function () {
                $window.location.href = website;
              }, 100);
              $sessionStorage.workstationData = {};
              $sessionStorage.workstationData.selectedTab = 0;
              $sessionStorage.workstationData.selectedLang = 'EN';
              $sessionStorage.jobData = [];
              stop();
              delete $sessionStorage.groupName;
              $sessionStorage.$reset();
            } else {
              $log.debug(data.data.errorMessage);
            }
          });
        };
        if (
          $scope.selectedIntermediary.intermediaryName ===
          appConstant.INTERMEDIARY_BINCKBANK
        ) {
          $scope.logout(
            $scope.logoutURL,
            appConstant.INTERMEDIARY_BINCKBANK_WEBISTE
          );
        } else if (
          $scope.selectedIntermediary.intermediaryName ===
          appConstant.INTERMEDIARY_ALEX
        ) {
          $scope.logout(
            $scope.logoutURL,
            appConstant.INTERMEDIARY_ALEX_WEBISTE
          );
        }
      };

      //Below method is to navigate to the linked meeting AR page-SFCBP-2725
      $scope.goToLinkedMeetingSH = function () {
        attendanceRequestService
          .getAttendanceRequestView(
            $scope.linkedMeetingId,
            $scope.languageCode,
            $sessionStorage.groupId,
            'SH'
          )
          .query()
          .$promise.then(
            function (data) {
              $state.go('linkedMeetingAttendanceRequest', {
                issuerId: $scope.linkedMeetingIssuerId,
                issuerName: $scope.linkedMeetingIssuerName,
                linkedISIN: $scope.isin,
                selectedIntermediaryName: $scope.selectedIntermediaryName,
                gmId: $scope.linkedMeetingId,
                selectedIndex: 1,
                attendanceDataDeatils: data,
                breadCrumbDetails: [data.meetingName],
              });
            },
            function (error) {
              return error;
            }
          );
      };
      //Below method is to route the 'i would like to vote for this meeting' button to respective pages, as part of SFCBP-1520
      $scope.votingInstructionCreation = function () {
        var selectedIndex = 1;
        if ($scope.userType === 'SP') {
          $scope.shareplanId = $sessionStorage.groupId;
          selectedIndex = 0;
        } else if ($scope.userType === 'SH') {
          $scope.shareplanId = 0;
        } else {
          $scope.shareplanId = null;
        }
        voteInstructionService
          .getShareClassList(
            $scope.gmId,
            $sessionStorage.groupId,
            $scope.languageCode,
            $scope.userType
          )
          .query()
          .$promise.then(
            function (data) {
              if (data.length > 0) {
                $state.go('goToVotingInstructions', {
                  meetingId: $scope.gmId,
                  issuerName: $scope.issuerName,
                  issuerId: $scope.issuerId,
                  shareHolderId: $sessionStorage.groupId,
                  virtualMeetingFlag: $scope.isVirtualMeetingEnabled,
                  selectedIndex: selectedIndex,
                  votingInstDetails: data,
                });
              } else if (data.length <= 0 && $scope.languageCode !== 'EN') {
                $scope.languageCode = 'EN';
                $scope.votingInstructionCreation();
              } else {
                $scope.showShareClassAlertBox = true;
              }
            },
            function (error) {
              return error;
            }
          );
      };

      //Below function is for register virtual attendance request
      $scope.createVirtualParticipationRequest = function () {
        $state.go(
          'vaRequestStep1',
          {
            selectedIndex: 1,
            issuerId: $stateParams.issuerId,
            gmId: $stateParams.gmId,
          },
          {
            location: true,
          }
        );
      };



      $scope.goToLinkedMeetingIN = function () {
        attendanceRequestService
          .getAttendanceRequestView(
            $scope.linkedMeetingData[0].meetingId,
            $scope.languageCode,
            $sessionStorage.groupId,
            'IN'
          )
          .query()
          .$promise.then(function (data) {
            $state.go('attendanceReqIntermediaryLinkedMeeting', {
              attendanceDataDeatils: $scope.attendanceData,
              linkedMeetingData: $scope.linkedMeetingData,
              issuerName: $scope.linkedMeetingData[0].issuerName,
              gmId: $scope.linkedMeetingData[0].meetingId,
              issuerId: $scope.linkedMeetingData[0].issuerId,
              isLinkedMeeting: true,
              selectedShareClass: $scope.selectedShareClass,
              shareholderRegistrationDetailVO: $scope.registrationList,
              shareHolderVOList: $scope.attendanceRequestData.shareHolderVOList,
              profileListData: $scope.profileListData,
              shTitleRequired: $scope.shTitleRequired,
              attendProxyBy: $scope.attendProxyBy,
              selectedProxyType: $scope.selectedProxyType,
              selectedProxyTitle: $scope.selectedProxyTitle,
              selectedProxyCountry: $scope.selectedProxyCountry,
              breadCrumbDetails: [data.meetingName],
            });
          });
      };

      $scope.goToAttendanceReqMeetingInfo = function () {
        $state.go($sessionStorage.fromState.name, $sessionStorage.fromParms);
      };

      $scope.shCustodianMessage = () =>
        htmlDecode(
          $filter('translate')('label_sh_different_custodian_message')
        );

      $scope.checkShareholderProxyTypeIsValid = function () {
        let isValid = true;

        if ($sessionStorage.usertype === 'SH' && $scope.attendanceData.sharePlanHolderVO.shareHolderType === 'LGL') {
          isValid = $scope.attendProxyBy !== 'none';
        }

        return isValid;
      };
    },
  ]);
