import './general-details.scss';
import { getVirtualParticipationButtonLabel } from '@/lib/labels';

angular
  .module('generalMeetingHomeModule')
  .controller('GeneralDetailsViewController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    '$log',
    '$filter',
    '$state',
    'generalMeetingService',
    'userGroupService',
    'voteInstructionService',
    'attendanceRequestService',
    'GeneralMeetingProgressService',
    'entitlementsService',
    'ModelWindowService',
    'ExtensionRequestService',
    'GeneralMeetingDataLanguageService',
    'shareHolderService',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      $log,
      $filter,
      $state,
      generalMeetingService,
      userGroupService,
      voteInstructionService,
      attendanceRequestService,
      GeneralMeetingProgressService,
      entitlementsService,
      ModelWindowService,
      ExtensionRequestService,
      GeneralMeetingDataLanguageService,
      shareHolderService
    ) {
      $scope.getVirtualParticipationButtonLabel = getVirtualParticipationButtonLabel;
      // -- StateParam input --
      var status = $stateParams.status;
      var newEndDateTime = $stateParams.newEndDateTime;
      var allExtnReqRejected = $stateParams.allExtnReqRejected;
      var flag = $stateParams.flag;
      var breadCrumbDetails = $stateParams.breadCrumbDetails[0];
      $scope.issuerId = $stateParams.issuerId;
      $scope.issuerName = $stateParams.issuerName;
      $scope.isTriodos = false;
      $scope.isSaatTriodos = false;
      $scope.radioVal = $stateParams.radioVal;
      $scope.dropdownVal = $stateParams.dropdownVal;
      $scope.landingThrough = $stateParams.landingThrough;
      $scope.showHideFlag = $stateParams.showHideFlag;
      $scope.gmId = $stateParams.meetingId ? $stateParams.meetingId : $sessionStorage.gmId;
      $scope.intermediaryDate = $stateParams.intermediaryDate;
      $scope.intermediaryDateTimeStamp = $stateParams.intermediaryDateTimeStamp;
      $scope.notAbleToVoteAttend = $stateParams.notAbleToVoteAttend;
      // --

      $scope.userType = $sessionStorage.usertype;

      if ($scope.issuerName) {
        $scope.isTriodos = $scope.issuerName.toLowerCase() === 'triodos bank n.v.';
        $scope.isSaatTriodos = $scope.issuerName.toLowerCase() === 'stichting administratiekantoor aandelen triodos bank';
      }


      $scope.documentsEN = null;
      if ($scope.userType) {
        $scope.publicPageGraph = 'loggedin';
      } else {
        $scope.publicPageGraph = 'public';
      }
      if ($scope.userType === 'IN' && (status === 'M' || status === 'C')) {
        $scope.hideButtons = true;
      } else {
        $scope.hideButtons = false;
      }
      if ($sessionStorage.workstationData) {
        $scope.languageCode =
          $sessionStorage.workstationData.selectedLang || 'EN';
        $scope.formLanguage =
          $sessionStorage.workstationData.selectedLang || 'EN';
      } else {
        $scope.languageCode = $sessionStorage.selectedLang;
        $scope.formLanguage = $sessionStorage.selectedLang;
      }
      $scope.voteCollectorListCount = 0;

      if ($sessionStorage.isPublicPage === true) {
        $scope.isPublicPage = true;
        $scope.userType = 'SH';
      } else {
        $scope.isPublicPage = false;
      }
      $scope.gmDeadlineModelConfig = {};
      $scope.extnRequestModelConfig = {};
      $scope.shareClassModelConfig = {};
      $scope.showVoteAttendBtn = true;
      //Below function is called to display language specific documents
      $scope.setDocuments = function () {
        $scope.generalMeetingView.gmDocumentVO = [];
        var initialiseENDoc = true;
        //displayWebEnabled - is used to check whether the document available in selected language and is allowed to display in web pages
        var displayWebEnabled = true;
        angular.forEach($scope.tempDocVoList, function (value) {
          if (
            !value.attachGmWebPage &&
            value.meetingDocAttachList.length > 0 &&
            value.meetingDocAttachList[0].logicalFileName &&
            value.meetingDocAttachList[0].languageCode === $scope.formLanguage
          ) {
            displayWebEnabled = false;
          }
          if (
            value.attachGmWebPage &&
            value.meetingDocAttachList.length > 0 &&
            value.meetingDocAttachList[0].logicalFileName
          ) {
            //Below changes are done to show documents in English if the documents are not available in other languages
            if (
              value.meetingDocAttachList[0].languageCode === 'EN' &&
              initialiseENDoc
            ) {
              $scope.documentsEN = [];
            }
            angular.forEach(value.meetingDocAttachList, function (value1) {
              if (value1.languageCode === $scope.formLanguage) {
                displayWebEnabled = true;
                $scope.generalMeetingView.gmDocumentVO.push(value);
                $scope.languageSpecificDoc = true;
                $scope.hideDocAccordion = false;
              }
              if (value1.languageCode === 'EN') {
                initialiseENDoc = false;
                $scope.documentsEN = $scope.documentsEN.concat(value);
              }
            });
          }
        });
        if (
          $scope.generalMeetingView.gmDocumentVO.length <= 0 &&
          $scope.documentsEN <= 0 &&
          displayWebEnabled
        ) {
          var data1 = $scope.masterDataCopy;
          data1 = GeneralMeetingDataLanguageService.getLanguageSpecificDataShareHolder(
            angular.copy(data1),
            'EN'
          );
          $scope.tempDocVoList = data1.gmDocumentVO;
          angular.forEach($scope.tempDocVoList, function (value) {
            if (
              value.attachGmWebPage &&
              value.meetingDocAttachList.length > 0 &&
              value.meetingDocAttachList[0].logicalFileName
            ) {
              //Below changes are done to show documents in English if the documents are not available in other languages
              if (
                value.meetingDocAttachList[0].languageCode === 'EN' &&
                initialiseENDoc
              ) {
                $scope.documentsEN = [];
              }
              angular.forEach(value.meetingDocAttachList, function (value1) {
                $scope.generalMeetingView.gmDocumentVO.push(value);
                $scope.languageSpecificDoc = true;
                $scope.hideDocAccordion = false;
                if (value1.languageCode === 'EN') {
                  initialiseENDoc = false;
                  $scope.documentsEN = $scope.documentsEN.concat(value);
                }
              });
            }
            $log.debug($scope.documentsEN);
          });
        } else if (!$scope.languageSpecificDoc && displayWebEnabled) {
          $scope.generalMeetingView.gmDocumentVO = $scope.generalMeetingView.gmDocumentVO.concat(
            $scope.documentsEN
          );
        }
      };

      $scope.canBlankVote = function() {
        if (
          $scope.isTriodos || $scope.isSaatTriodos
        ) {
          return true;
        } else {
          return false;
        }
      };

      //creating the graph based on the language -- for date values
      $scope.getLanguageSpecificDates = function (lang) {
        var langScheduleVo = '';
        if (lang === 'NL') {
          langScheduleVo = $filter('filter')(
            $scope.generalMeetingView.gmScheduleVO.gmDatesBasedOnLanguage,
            { languageCode: 'NL' },
            true
          );
        } else if (lang === 'FR') {
          langScheduleVo = $filter('filter')(
            $scope.generalMeetingView.gmScheduleVO.gmDatesBasedOnLanguage,
            { languageCode: 'FR' },
            true
          );
        } else if (lang === 'EN') {
          langScheduleVo = $filter('filter')(
            $scope.generalMeetingView.gmScheduleVO.gmDatesBasedOnLanguage,
            { languageCode: 'EN' },
            true
          );
        } else if (lang === 'DE') {
          langScheduleVo = $filter('filter')(
            // @@@ TODO: use DE as language code (or get rid of gmDatesBasedOnLanguage completely)
            $scope.generalMeetingView.gmScheduleVO.gmDatesBasedOnLanguage,
            { languageCode: 'DE' },
            true
          );
        } else if (lang === 'ES') {
          langScheduleVo = $filter('filter')(
              $scope.generalMeetingView.gmScheduleVO.gmDatesBasedOnLanguage,
              { languageCode: 'ES' },
              true
          );
        }
        $scope.langGmScheduleVO = $scope.generalMeetingView.gmScheduleVO;
        $scope.langGmScheduleVO.generalMeetingDate =
          langScheduleVo[0].generalMeetingDateLang;
        $scope.langGmScheduleVO.recordDate = langScheduleVo[0].recordDateLang;
        $scope.langGmScheduleVO.shareholderAccessDate =
          langScheduleVo[0].shareholderAccessDateLang;
        $scope.langGmScheduleVO.shareholderDeadlineDate =
          langScheduleVo[0].shareholderDeadlineDateLang;
        $scope.langGmScheduleVO.convocationDate =
          langScheduleVo[0].convocationDateLang;
        $scope.langGmScheduleVO.publicationDate =
          langScheduleVo[0].publicationDateLang;
        $scope.langGmScheduleVO.intermediaryAccessDate =
          langScheduleVo[0].intermediaryAccessDateLang;
        $scope.langGmScheduleVO.intermediaryDeadlineDate =
          langScheduleVo[0].intermediaryDeadlineDateLang;
        $scope.langGmScheduleVO.registrationReleaseDate =
          langScheduleVo[0].registrationReleaseDateLang;
        $scope.langGmScheduleVO.votingResultDate =
          langScheduleVo[0].votingResultDateLang;
        var currentDate = '';
        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              if ($scope.timeZoneString === 'CET') {
                currentDate = $filter('filter')(
                  data,
                  {
                    timezone: 'CET',
                  },
                  true
                );
              } else if ($scope.timeZoneString === 'GMT') {
                currentDate = $filter('filter')(
                  data,
                  {
                    timezone: 'GMT',
                  },
                  true
                );
              }
              // calling the progress bar data creation with language specific dates
              $scope.gmProgressData = GeneralMeetingProgressService.getGeneralMeetingProgressData(
                currentDate[0].currentMillis,
                $scope.langGmScheduleVO,
                flag
              );
            },
            function (error) {
              $log.debug(error);
            }
          );
      };

      // when language changes
      $scope.$on('onLanguageChangeShareHolder', function (event, lang) {
        $scope.formLanguage = lang;
        $scope.languageCode = lang;
        $scope.countryList = $filter('filter')(
          $scope.masterCountryList,
          {
            languageCode: lang,
          },
          true
        );
        $scope.getLanguageSpecificDates(lang);
        $scope.processLangData(lang);
      });

      // bind updated language data to template.
      $scope.processLangData = function (lang) {
        var filterData = $scope.masterDataCopy;
        filterData = GeneralMeetingDataLanguageService.getLanguageSpecificDataShareHolder(
          angular.copy(filterData),
          lang
        );
        $scope.generalMeetingView = filterData;
        $scope.hideDocAccordion = true;
        $scope.languageSpecificDoc = false;
        $scope.tempDocVoList = filterData.gmDocumentVO;
        $scope.agendaItems = $scope.generalMeetingView.gmAgendaVO;
        $scope.countryName = $filter('filter')(
          $scope.countryList,
          {
            isoCountryCode: $scope.generalMeetingView.gmDetailVOList[0].country,
          },
          true
        );

        if ($scope.agendaItems.length > 0) {
          angular.forEach($scope.agendaItems, function (value, key) {
            $scope.agendaItems[key].agendaDetailVOList[0].agendaTitle = value
              .agendaDetailVOList[0].agendaTitle
              ? String(value.agendaDetailVOList[0].agendaTitle).replace(
                  /<[^>]+>/gm,
                  ''
                )
              : '';
          });
        }
        angular.forEach($scope.agendaItems, function (item) {
          if (item.isVoteable === true) {
            $scope.enableVote = true;
          }
        });

        $scope.shareclasses = $scope.generalMeetingView.gmShareClassVO;
        if ($scope.shareclasses.length > 0) {
          $scope.voteCollectorList =
            $scope.generalMeetingView.gmShareClassVO[0].authorizedVoteCollectorList;
          angular.forEach($scope.voteCollectorList, function (value, key) {
            if (!$scope.voteCollectorList[key].isDeleted) {
              $scope.voteCollectorListCount++;
            }
          });
        }
        // to display only documents where web
        // page is selected as true
        if ($scope.tempDocVoList.length > 0) {
          $scope.setDocuments();
        }
      };
      // Below is the method to hide or show accordian
      $scope.showHideDocs = function (agenda) {
        agenda.showDetails = !agenda.showDetails;
      };
      // Below is the function used to fetch issuer logo
      $scope.getIssuerLogo = function (issuerId) {
        userGroupService.getIssuerGroupLogo(issuerId).then(
          function (response) {
            $scope.issuerLogoPresent = false;
            $scope.issuerGroupDetails = response.data;
            // Do not change the below condition. It is for issuer logo display fix. REST response has ""
            if ($scope.issuerGroupDetails.logoBaseStr) {
              $scope.issuerLogoPresent = true;
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      $scope.loading = true;
      if ($scope.userType === 'SP') {
        $scope.shareplanId = $sessionStorage.groupId;
        $scope.shareholderId = $sessionStorage.groupId;
        $scope.selectedIndex = 0;
      } else if ($scope.userType === 'SH') {
        $scope.shareplanId = 0;
        $scope.shareholderId = $sessionStorage.groupId;
        $scope.selectedIndex = 1;
      } else {
        $scope.shareplanId = 0;
        $scope.shareholderId = 0;
        $scope.selectedIndex = 1;
      }
      // Below is the function used to fetch country drop-down list
      if (
        $sessionStorage.isPublicPage ||
        undefined === $sessionStorage.isPublicPage
      ) {
        generalMeetingService
          .getCountryListMultilanguage()
          .query()
          .$promise.then(
            function (response) {
              $scope.masterCountryList = response;
              $scope.countryList = $filter('filter')(
                response,
                {
                  languageCode: $scope.languageCode,
                },
                true
              );
            },
            function (error) {
              $log.debug(error);
            }
          );
      } else {
        generalMeetingService
          .getCountryListByLanguage($scope.languageCode)
          .query()
          .$promise.then(
            function (response) {
              $scope.masterCountryList = response.countries;
              $scope.countryList = response.countries;
            },
            function (error) {
              $log.debug(error);
            }
          );
      }

      // Below is the function to check whether extension request got rejected or not
      if ($scope.userType === 'IN') {
        var currentDate = '';
        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              if ($scope.intermediaryDate.indexOf('CET') > -1) {
                currentDate = $filter('filter')(
                  data,
                  {
                    timezone: 'CET',
                  },
                  true
                );
              } else if ($scope.intermediaryDate.indexOf('GMT') > -1) {
                currentDate = $filter('filter')(
                  data,
                  {
                    timezone: 'GMT',
                  },
                  true
                );
              }
              if (
                $scope.intermediaryDateTimeStamp < currentDate[0].currentMillis
              ) {
                $scope.deadlindateCrossed = true;
              } else {
                $scope.deadlindateCrossed = false;
              }
              ExtensionRequestService.getExtensionReqStatus(
                $scope.gmId,
                $sessionStorage.groupId
              )
                .query()
                .$promise.then(
                  function (extensionReqResults) {
                    if (extensionReqResults.code) {
                      $scope.extReqStatus = extensionReqResults.code;
                    } else {
                      $scope.extReqStatus =
                        extensionReqResults.data.errors[0].errorCode;
                    }
                  },
                  function (error) {
                    $log.debug(error);
                    if (error.status) {
                      $scope.extReqStatus = error.status;
                    } else {
                      $scope.extReqStatus = error.data.errors[0].errorCode;
                    }
                  }
                );
            },
            function (error) {
              $log.debug(error);
            }
          );
      }

      /** Validation method to enable buttons on the screen
       * Iterate through all share classes to identify participation methods **/
       function validateParticipationMethods(data) {
        $scope.attendanceRequestAllowed = data.gmShareClassVO.find(
            shareClass => shareClass.participationMethods.find(
                p => p.participationMethod === 'PHYS'
                    && p.canBeRequestedBy === $scope.userType)) !== undefined;
        $scope.votingInstructionAllowed = data.gmShareClassVO.find(
            shareClass => shareClass.participationMethods.find(
                p => p.canBeRequestedBy === $scope.userType &&
                    (p.participationMethod === 'PRXY' || p.participationMethod
                        === 'EVOT'))) !== undefined;
        $scope.virtualParticipationAllowed = data.gmShareClassVO.find(
            shareClass => shareClass.participationMethods.find(
                p => p.canBeRequestedBy === $scope.userType &&
                    p.participationMethod === 'VIRT')) !== undefined;
      }

      // Below is the function used to fetch all the data
      // related to general meeting on load of the page
      generalMeetingService
        .getGeneralMeetingView($scope.gmId)
        .query()
        .$promise.then(
          function (data) {
            $scope.instructionSubmissionAllowed = false;
            $scope.masterDataCopy = data;
            data = GeneralMeetingDataLanguageService.getLanguageSpecificDataShareHolder(
              angular.copy(data),
              $scope.languageCode
            );
            $scope.generalMeetingView = data;
            $scope.hideDocAccordion = true;
            $scope.languageSpecificDoc = false;
            $scope.tempDocVoList = data.gmDocumentVO;
            $scope.generalMeetingStatus = $scope.generalMeetingView.status;
            $scope.generalMeetingId =
              $scope.generalMeetingView.generalMeetingId;
            $scope.issuerId = $scope.generalMeetingView.issuerId;
            $scope.issuerName = $scope.generalMeetingView.issuerName;
            $scope.agendaItems = $scope.generalMeetingView.gmAgendaVO;
            $scope.timeZoneString =
              $scope.generalMeetingView.gmScheduleVO.timezoneStr;
            $scope.shareholderAccessDate =
                $scope.generalMeetingView.gmScheduleVO.shareholderAccessDate;
            validateParticipationMethods(data);
            generalMeetingService
              .getCurrentCETTime()
              .query()
              .$promise.then(
                function (data) {
                  var todaysDate = data[0].currentMillis;
                  $scope.yetToStart = false;
                  if (
                    ($scope.userType === 'SH' &&
                      $scope.generalMeetingView.shAttendEnabled === 'N') ||
                    ($scope.userType === 'SP' &&
                      $scope.generalMeetingView.spAttendEnabled === 'N')
                  ) {
                    $scope.hideAttendButton = true;
                  } else {
                    $scope.hideAttendButton = false;
                  }
                  if (
                    $scope.userType === 'SP' &&
                    $scope.generalMeetingView.spVoteEnabled === 'N'
                  ) {
                    $scope.hideVoteBtn = false;
                  } else {
                    $scope.hideVoteBtn = true;
                  }
                  $scope.deadLineDate =
                    $scope.generalMeetingView.gmScheduleVO.shareholderDeadlineDateMiilis;
                  $scope.issuerName = $scope.generalMeetingView.issuerName;
                  var shareholderAccessDateMiilis =
                    $scope.generalMeetingView.gmScheduleVO
                      .shareholderAccessDateMiilis;
                  var intermediaryAccessDate =
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryAccessDateMiilis;
                  var deadLineDateTime = $scope.deadLineDate;

                  if (
                    deadLineDateTime < todaysDate &&
                    $scope.userType !== 'IN'
                  ) {
                    $scope.showVoteAttendBtn = false;
                    $scope.yetToStart = false;
                  }
                  if (
                    shareholderAccessDateMiilis > todaysDate &&
                    ($scope.userType === 'SH' || $scope.userType === 'SP')
                  ) {
                    $scope.showVoteAttendBtn = 1;
                  }
                  if (
                      (shareholderAccessDateMiilis > todaysDate &&
                      deadLineDateTime > todaysDate ) &&
                      ($scope.userType === 'SH' || $scope.userType === 'SP')
                  ) {
                    $scope.yetToStart = true;
                    $scope.instructionSubmissionAllowed = true;
                  }

                  if (
                    intermediaryAccessDate > todaysDate &&
                    $scope.userType === 'IN'
                  ) {
                    $scope.showVoteAttendBtn = 2;
                  } // logic for hover over text display when disabled due to shareholder access date.
                  if (
                    shareholderAccessDateMiilis > todaysDate &&
                    ($scope.userType === 'SH' || $scope.userType === 'SP')
                  ) {
                    $scope.yetToStart = true;
                    $scope.showVoteAttendBtn = false;
                  }
                  if ($scope.agendaItems.length > 0) {
                    angular.forEach($scope.agendaItems, function (value, key) {
                      $scope.agendaItems[
                        key
                      ].agendaDetailVOList[0].agendaTitle = value
                        .agendaDetailVOList[0].agendaTitle
                        ? String(
                            value.agendaDetailVOList[0].agendaTitle
                          ).replace(/<[^>]+>/gm, '')
                        : '';
                    });
                  }
                  angular.forEach($scope.agendaItems, function (item) {
                    if (item.isVoteable === true) {
                      $scope.enableVote = true;
                    }
                  });

                  $scope.shareclasses =
                    $scope.generalMeetingView.gmShareClassVO;
                  if ($scope.shareclasses.length > 0) {
                    $scope.voteCollectorList =
                      $scope.generalMeetingView.gmShareClassVO[0].authorizedVoteCollectorList;
                    angular.forEach($scope.voteCollectorList, function (
                      value,
                      key
                    ) {
                      if (!$scope.voteCollectorList[key].isDeleted) {
                        $scope.voteCollectorListCount++;
                      }
                    });
                  }
                  // to display only documents where web
                  // page is selected as true
                  if ($scope.tempDocVoList.length > 0) {
                    $scope.setDocuments();
                  }
                  $scope.gmFeatures = $scope.generalMeetingView.gmFeaturesVO;
                  $scope.getIssuerLogo($scope.issuerId);
                  if ($scope.languageCode) {
                    $scope.getLanguageSpecificDates($scope.languageCode);
                  } else {
                    $scope.gmProgressData = GeneralMeetingProgressService.getGeneralMeetingProgressData(
                      todaysDate,
                      $scope.generalMeetingView.gmScheduleVO,
                      flag
                    );
                  }
                  $scope.countryName = $filter('filter')(
                    $scope.countryList,
                    {
                      isoCountryCode:
                        $scope.generalMeetingView.gmDetailVOList[0].country,
                    },
                    true
                  );
                  $scope.timeZone = $filter('filter')(
                    $scope.timeZoneList,
                    {
                      timezoneID:
                        $scope.generalMeetingView.gmScheduleVO.timezone,
                    },
                    true
                  );
                  $scope.loading = false;
                },
                function (error) {
                  $log.debug(error);
                }
              );
          },
          function (error) {
            $scope.loading = false;
            $log.debug(error);
          }
        );
      // Below is the function call to download Agenda documents in agenda section
      $scope.downloadAgendaDocument = function (agendaId) {
        var pathList = window.location.pathname.split('/');
        if (pathList[1] === 'cbp') {
          $scope.downloadAgendaDoc =
            window.location.origin +
            '/' +
            pathList[1] +
            '/v1/shareholderusers/generelMeetings/agendas/' +
            agendaId +
            '/' +
            $scope.languageCode;
        } else {
          $scope.downloadAgendaDoc =
            window.location.origin +
            '/v1/shareholderusers/generelMeetings/agendas/' +
            agendaId +
            '/' +
            $scope.languageCode;
        }
      };
      // Below is the function call to download Meeting documents in Document section
      $scope.downloadGMDocument = function (docId) {
        var pathList = window.location.pathname.split('/');
        if (pathList[1] === 'cbp' && !$sessionStorage.userId) {
          $scope.downloadGmDoc =
            window.location.origin +
            '/' +
            pathList[1] +
            '/v1/shareholderusers/generelMeetings/' +
            docId +
            '/' +
            $scope.languageCode;
        } else if (pathList[1] === 'cbp' && $sessionStorage.userId) {
          $scope.downloadGmDoc =
            window.location.origin +
            '/' +
            pathList[1] +
            '/v1/generalmeetingdetails/documents/' +
            docId +
            '/' +
            $scope.languageCode +
            '?authid=' +
            $sessionStorage.userId;
        } else if ($sessionStorage.userId) {
          $scope.downloadGmDoc =
            window.location.origin +
            '/v1/generalmeetingdetails/documents/' +
            docId +
            '/' +
            $scope.languageCode +
            '?authid=' +
            $sessionStorage.userId;
        } else {
          $scope.downloadGmDoc =
            window.location.origin +
            '/v1/shareholderusers/generelMeetings/' +
            docId +
            '/' +
            $scope.languageCode;
        }
      };
      // Below is the function call to add class to change color for progress-chart
      $scope.getProgressClass = function (isPastDue, index) {
        return GeneralMeetingProgressService.getProgressClass(isPastDue, index);
      };
      /*
       * Below is the function call to check whether shareholder
       * dead line date is crossed or not in share holder
       * workstation and intermediary dead line date is crossed
       * or not in intermediary workstation to navigate to
       * voting instructions page
       */
      $scope.votingInstructionView = function (
        gmId,
        issuerId,
        shareholderId,
        selectedIndex,
        deadLineDate,
        virtualParticipationAllowed
      ) {
        var deadLineDateTime = deadLineDate;
        var todaysDate = '';

        // First two if condition added to check intermediary/shareholder access date

        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              todaysDate = data[0].currentMillis;

              if ($scope.userType === 'IN') {
                $state.go('votingInfo', {
                  type: 'vote',
                  gmId: gmId,
                  issuerId: issuerId,
                  intermediaryDateTimeStamp:
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryDeadlineDateMiilis,
                  intermediaryDate:
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryDeadlineDate +
                    ' ' +
                    $scope.generalMeetingView.gmScheduleVO.timezoneStr,
                  intermediaryAccessDate:
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryAccessDateMiilis,
                  intermediaryAccessDateStr:
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryAccessDate +
                    ' ' +
                    $scope.generalMeetingView.gmScheduleVO.timezoneStr,
                  issuerName: $scope.generalMeetingView.issuerName,
                  gmDate:
                    $scope.generalMeetingView.gmScheduleVO.generalMeetingDate,
                  langCode: $scope.langCode,
                  gmStatus: status,
                  newEndDateTimeStamp: newEndDateTime,
                  allExtnReqRejected: allExtnReqRejected,
                  breadCrumbDetails: [breadCrumbDetails],
                });
              } else if ($scope.showVoteAttendBtn === 1) {
                $scope.shareClassModelConfig.modalHeader = '';
                $scope.shareClassModelConfig.modalBody =
                  $filter('translate')('label_youCanAttend') +
                  $scope.generalMeetingView.gmScheduleVO.shareholderAccessDate;
                ModelWindowService.showModelWindow('shareClassAlertBox');
              } else if ($scope.showVoteAttendBtn === 2) {
                $scope.shareClassModelConfig.modalHeader = '';
                $scope.shareClassModelConfig.modalBody =
                  $filter('translate')('label_youCanAttend') +
                  $scope.generalMeetingView.gmScheduleVO.intermediaryAccessDate;
                ModelWindowService.showModelWindow('shareClassAlertBox');
              } else if (
                deadLineDateTime < todaysDate &&
                ($scope.userType === 'SH' || $scope.userType === 'SP')
              ) {
                $scope.gmDeadlineModelConfig.modalHeader = '';
                $scope.gmDeadlineModelConfig.modalBody = $filter('translate')(
                  'deadLineModalVI_body'
                );
                ModelWindowService.showModelWindow('gmDeadline');
              } else if (
                undefined === $sessionStorage.isPublicPage ||
                $sessionStorage.isPublicPage
              ) {
                attendanceRequestService
                  .getShareholderAccess(gmId, 'VI')
                  .query()
                  .$promise.then(
                    function (data) {
                      // redirect to Login Page
                      $sessionStorage.voteObject = {
                        issuerName: $scope.issuerName,
                        meetingId: gmId,
                        issuerId: issuerId,
                        meetingName: data.meetingName,
                        virtualMeetingFlag: $scope.virtualParticipationAllowed,
                      };
                      $state.go('shareholderlogin', {});
                    },
                    function (error) {
                      $log.debug(error);
                      $scope.shareClassModelConfig.modalHeader = $filter(
                        'translate'
                      )('label_votingInsCreatErr');
                      $scope.shareClassModelConfig.modalBody = $filter(
                        'translate'
                      )('label_voteNotPossible');
                      ModelWindowService.showModelWindow('shareClassAlertBox');
                    }
                  );
              } else {
                voteInstructionService
                  .getShareClassList(
                    gmId,
                    shareholderId,
                    $scope.languageCode,
                    $sessionStorage.usertype
                  )
                  .query()
                  .$promise.then(
                    function (data) {
                      if (data.length > 0) {
                        $state.go('votingInstructions', {
                          issuerName: $scope.issuerName,
                          meetingId: gmId,
                          issuerId: issuerId,
                          shareHolderId: shareholderId,
                          selectedIndex: selectedIndex,
                          votingInstDetails: data,
                          breadCrumbDetails: [],
                          virtualMeetingFlag: virtualParticipationAllowed,
                        });
                      } else if (
                        data.length <= 0 &&
                        $scope.languageCode !== 'EN'
                      ) {
                        $scope.languageCode = 'EN';
                        $scope.votingInstructionView(
                          gmId,
                          issuerId,
                          shareholderId,
                          selectedIndex,
                          deadLineDate,
                          virtualParticipationAllowed
                        );
                      } else if (
                        undefined === $sessionStorage.isPublicPage ||
                        $sessionStorage.isPublicPage === true
                      ) {
                        // redirect to Login Page
                        $sessionStorage.voteObject = {
                          issuerName: $scope.issuerName,
                          meetingId: gmId,
                          issuerId: issuerId,
                          virtualMeetingFlag:
                            $scope.virtualParticipationAllowed,
                        };
                        $state.go('shareholderlogin', {});
                      } else {
                        $scope.shareClassModelConfig.modalHeader = $filter(
                          'translate'
                        )('label_votingInsCreatErr');
                        $scope.shareClassModelConfig.modalBody = $filter(
                          'translate'
                        )('label_voteNotPossible');
                        ModelWindowService.showModelWindow(
                          'shareClassAlertBox'
                        );
                      }
                    },
                    function (error) {
                      return error;
                    }
                  );
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      /* Below is the function  call to check whether shareholder dead line date is crossed or not in share holder workstation
                      and intermediary dead line date is crossed or not in intermediary workstation to navigate to attendance request page*/
      $scope.attendanceRequestView = function (
        gmId,
        issuerId,
        issuerName,
        shareplanId,
        selectedIndex,
        deadLineDate,
        buttonFlag,
        virtualParticipationAllowed
      ) {
        var deadLineDateTime = deadLineDate;
        var todaysDate = '';
        //First two if condition added to check intermediary/shareholder access date

        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              todaysDate = data[0].currentMillis;

              if ($scope.userType === 'IN') {
                $state.go('attendanceReqMeetingInfo', {
                  type: 'attend',
                  gmId: gmId,
                  issuerId: issuerId,
                  intermediaryDateTimeStamp:
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryDeadlineDateMiilis,
                  intermediaryDate:
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryDeadlineDate +
                    ' ' +
                    $scope.generalMeetingView.gmScheduleVO.timezoneStr,
                  intermediaryAccessDate:
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryAccessDateMiilis,
                  intermediaryAccessDateStr:
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryAccessDate +
                    ' ' +
                    $scope.generalMeetingView.gmScheduleVO.timezoneStr,
                  issuerName: $scope.generalMeetingView.issuerName,
                  gmDate:
                    $scope.generalMeetingView.gmScheduleVO.generalMeetingDate,
                  langCode: $scope.langCode,
                  gmStatus: status,
                  newEndDateTimeStamp: newEndDateTime,
                  allExtnReqRejected: allExtnReqRejected,
                  breadCrumbDetails: [breadCrumbDetails],
                });
              } else if ($scope.showVoteAttendBtn === 1) {
                $scope.shareClassModelConfig.modalHeader = '';
                $scope.shareClassModelConfig.modalBody =
                  $filter('translate')('label_youCanAttend') +
                  $scope.generalMeetingView.gmScheduleVO.shareholderAccessDate;
                ModelWindowService.showModelWindow('shareClassAlertBox');
              } else if ($scope.showVoteAttendBtn === 2) {
                $scope.shareClassModelConfig.modalHeader = '';
                $scope.shareClassModelConfig.modalBody =
                  $filter('translate')('label_youCanAttend') +
                  $scope.generalMeetingView.gmScheduleVO.intermediaryAccessDate;
                ModelWindowService.showModelWindow('shareClassAlertBox');
              } else if (
                deadLineDateTime < todaysDate &&
                ($scope.userType === 'SH' || $scope.userType === 'SP')
              ) {
                $scope.gmDeadlineModelConfig.modalHeader = '';
                $scope.gmDeadlineModelConfig.modalBody = $filter('translate')(
                  'deadLineModal_body'
                );
                ModelWindowService.showModelWindow('gmDeadline');
              } else if (
                undefined === $sessionStorage.isPublicPage ||
                $sessionStorage.isPublicPage
              ) {
                attendanceRequestService
                  .getShareholderAccess(gmId, 'AR')
                  .query()
                  .$promise.then(
                    function (data) {
                      // redirect to Login Page
                      $sessionStorage.attendanceRequestObject = {
                        issuerName: issuerName,
                        issuerId: issuerId,
                        gmId: gmId,
                        sharePlanId: $scope.shareplanId,
                        meetingName: data.meetingName,
                        buttonFlag: buttonFlag,
                        virtualMeetingFlag: $scope.virtualParticipationAllowed,
                      };
                      $state.go('shareholderlogin', {});
                    },
                    function (error) {
                      showAttendDisabledPopup(issuerName);
                    }
                  );
              } else {
                attendanceRequestService
                  .getAttendanceRequestView(
                    gmId,
                    $scope.languageCode,
                    shareplanId,
                    $sessionStorage.usertype
                  )
                  .query()
                  .$promise.then(
                    function (data) {
                      if (data && data.shareClassISINVO.length > 0) {
                        $state.go('attendanceRequest', {
                          issuerId: issuerId,
                          issuerName: issuerName,
                          gmId: gmId,
                          spVoteEnabled:
                            $scope.generalMeetingView.spVoteEnabled,
                          sharePlanId: shareplanId,
                          selectedIndex: selectedIndex,
                          attendanceDataDeatils: data,
                          breadCrumbDetails: [data.meetingName],
                          virtualMeetingFlag: virtualParticipationAllowed,
                        });
                      } else if (
                        data.shareClassISINVO.length <= 0 &&
                        $scope.languageCode !== 'EN'
                      ) {
                        $scope.languageCode = 'EN';
                        $scope.attendanceRequestView(
                          gmId,
                          issuerId,
                          issuerName,
                          shareplanId,
                          selectedIndex,
                          deadLineDate,
                          buttonFlag,
                          virtualParticipationAllowed
                        );
                      } else {
                        showAttendDisabledPopup(issuerName);
                      }
                    },
                    function (error) {
                      return error;
                    }
                  );
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };

      /* Below is the function  call to check whether shareholder dead line date is crossed or not in share holder workstation
                      and intermediary dead line date is crossed or not in intermediary workstation to navigate to virtual attendance request page*/
      $scope.virtualAttendanceRequestView = function (
        gmId,
        issuerId,
        issuerName,
        shareplanId,
        selectedIndex,
        deadLineDate,
        buttonFlag
      ) {
        var deadLineDateTime = deadLineDate;
        var todaysDate = '';

        //First two if condition added to check intermediary/shareholder access date
        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              todaysDate = data[0].currentMillis;

              if ($scope.userType === 'IN') {
                $state.go('virtualParticipationView', {
                  gmId: gmId,
                  issuerId: issuerId,
                  issuerName: $scope.generalMeetingView.issuerName,
                  generalMeetingDateMillis:
                    $scope.generalMeetingView.gmScheduleVO
                      .generalMeetingDateMiilis,
                  intermediaryDeadlineDateMillis:
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryDeadlineDateMiilis,
                  intermediaryAccessDateMillis:
                    $scope.generalMeetingView.gmScheduleVO
                      .intermediaryAccessDateMiilis,
                  timeZone: $scope.generalMeetingView.gmScheduleVO.timezoneStr,
                  breadCrumbDetails: [
                    $scope.generalMeetingView.gmDetailVOList[0].meetinName,
                  ],
                });
              } else if ($scope.showVoteAttendBtn === 1) {
                $scope.shareClassModelConfig.modalHeader = '';
                $scope.shareClassModelConfig.modalBody =
                  $filter('translate')('label_youCanAttend') +
                  $scope.generalMeetingView.gmScheduleVO.shareholderAccessDate;
                ModelWindowService.showModelWindow('shareClassAlertBox');
              } else if ($scope.showVoteAttendBtn === 2) {
                $scope.shareClassModelConfig.modalHeader = '';
                $scope.shareClassModelConfig.modalBody =
                  $filter('translate')('label_youCanAttend') +
                  $scope.generalMeetingView.gmScheduleVO.intermediaryAccessDate;
                ModelWindowService.showModelWindow('shareClassAlertBox');
              } else if (
                deadLineDateTime < todaysDate &&
                ($scope.userType === 'SH' || $scope.userType === 'SP')
              ) {
                $scope.gmDeadlineModelConfig.modalHeader = '';
                $scope.gmDeadlineModelConfig.modalBody = $filter('translate')(
                  'deadLineModal_body'
                );
                ModelWindowService.showModelWindow('gmDeadline');
              } else if (
                undefined === $sessionStorage.isPublicPage ||
                $sessionStorage.isPublicPage
              ) {
                // if the attend virtually button is show, the meeting can be attended,
                // we can redirect the user to the login page without further checks
                $state.go('shareholderlogin', {});
              } else {
                if ($scope.userType === 'SP') {
                  entitlementsService.list(gmId, 'EN').then((response) => {
                    if (response.data.entitlements.length > 0) {
                      $state.go('vaRequestStep1', {
                        issuerId: issuerId,
                        gmId: gmId,
                        selectedIndex: selectedIndex,
                      });
                    } else {
                      showNoEntitlementsPopup();
                    }
                  });
                } else {
                  shareHolderService
                    .getShareholderShareClassList(gmId, $scope.languageCode)
                    .query()
                    .$promise.then(
                      function (data) {
                        if (data && data.shareClassISINVO.length > 0) {
                          $state.go('vaRequestStep1', {
                            issuerId: issuerId,
                            gmId: gmId,
                            selectedIndex: selectedIndex,
                          });
                        } else if (
                          data.shareClassISINVO.length <= 0 &&
                          $scope.languageCode !== 'EN'
                        ) {
                          $scope.languageCode = 'EN';
                          $scope.attendanceRequestView(
                            gmId,
                            issuerId,
                            issuerName,
                            shareplanId,
                            selectedIndex,
                            deadLineDate,
                            buttonFlag,
                            virtualParticipationAllowed
                          );
                        } else {
                          $scope.shareClassModelConfig.modalHeader = $filter(
                            'translate'
                          )('label_modalAttReqCreatErr');
                          $scope.shareClassModelConfig.modalBody = $filter(
                            'translate'
                          )('label_attendNotPossible');
                          ModelWindowService.showModelWindow(
                            'shareClassAlertBox'
                          );
                        }
                      },
                      function (error) {
                        return error;
                      }
                    );
                }
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };

      /**
       * Angular-translate escapes all htmlentities, so if we want to
       * render html in the popup modal, we need to decode them again
       *
       * See {@link @/lib/htmlDecode.js}
       */
      function showAttendDisabledPopup(issuerName) {
        $scope.shareClassModelConfig.modalHeader = $filter('translate')(
          'label_modalAttReqCreatErr'
        );

        $scope.shareClassModelConfig.modalBody =
          $filter('translate')('label_attendNotEnabled1').toString() +
          ' ' +
          issuerName +
          '. ' +
          $filter('translate')('label_attendNotEnabled2').toString() +
          '.';
        ModelWindowService.showModelWindow('shareClassAlertBox');
      }

      function showNoEntitlementsPopup() {
        $scope.shareClassModelConfig.modalHeader = $filter('translate')(
          'label_modalVirtualReqCreateErr'
        );
        $scope.shareClassModelConfig.modalBody = $filter('translate')(
          'label_modalVirtualReqNoEntitlements'
        );
        ModelWindowService.showModelWindow('shareClassAlertBox');
      }
    },
  ]);
