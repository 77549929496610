/**
 * @ngdoc controller
 * @name dwtModule.DwtEventClaimsController
 * @description This controller is used to view the details of DWT event created
 *              in create DWT event page
 * @requires ViewDwtDetailService is the service which is being used to interact
 *           with REST
 * @requires PaginationSvc is the service which is being used to pagination
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $filter to translate messages which are declared in locale files and filter the array based on particular value
 * @requires $state for routing
 */
'use strict';

angular.module('dwtModule').controller('DwtEventClaimsController', [
  '$scope',
  '$sessionStorage',
  '$filter',
  '$log',
  '$state',
  '$timeout',
  '$anchorScroll',
  '$location',
  'ViewDwtDetailService',
  'viewDwtClaimDetailModal',
  'PaginationSvc',
  'fileUploadSvc',
  'ModelWindowService',
  'dwtFileDownloadSvc',
  function (
    $scope,
    $sessionStorage,
    $filter,
    $log,
    $state,
    $timeout,
    $anchorScroll,
    $location,
    ViewDwtDetailService,
    viewDwtClaimDetailModal,
    PaginationSvc,
    fileUploadSvc,
    ModelWindowService,
    dwtFileDownloadSvc
  ) {
    $scope.userType = $sessionStorage.usertype;
    $scope.userId = $sessionStorage.userId;
    $scope.select = {};
    $scope.downloadErrorConfig = {};
    $scope.pageNo = 1;
    $scope.totalQRFCount = 0;
    $scope.totalPECount = 0;
    $scope.countIterationFlag = true;
    var userGroupId = $sessionStorage.groupId;

    $scope.timeZoneString = 'CET';
    var todaysDate = '';
    $scope.loading = true;
    ViewDwtDetailService.getCurrentCETTime()
      .query()
      .$promise.then(
        function (data) {
          if ($scope.timeZoneString === 'CET') {
            todaysDate = $filter('filter')(data, { timezone: 'CET' }, true);
          } else if ($scope.timeZoneString === 'GMT') {
            todaysDate = $filter('filter')(data, { timezone: 'GMT' }, true);
          }
          if (todaysDate[0].currentMillis > $scope.qrfEndDate) {
            $scope.endDateCrossed = true;
          } else {
            $scope.endDateCrossed = false;
          }
          if (todaysDate[0].currentMillis > $scope.peEndDate) {
            $scope.peEndDateCrossed = true;
          } else {
            $scope.peEndDateCrossed = false;
          }
          $scope.loading = false;
        },
        function (error) {
          $scope.loading = false;
          $log.debug(error);
        }
      );

    $scope.otherFormToolTip =
      '<div>' +
      $filter('translate')('label_ortherFormtooltip1') +
      '</div>' +
      '<br>' +
      '<div>' +
      $filter('translate')('label_ortherFormtooltip3') +
      '</div>' +
      '<br>' +
      '<div>' +
      $filter('translate')('label_ortherFormtooltip2') +
      '</div>';
    $scope.ibFormToolTip =
      '<div>' +
      $filter('translate')('label_ibFormtooltip1') +
      '</div>' +
      '<br>' +
      '<div>' +
      $filter('translate')('label_ibFormtooltip2') +
      '</div>';
    $scope.modalSubRej = false;
    $scope.modalQRFClaim = false;
    $scope.modalPEClaim = false;
    $scope.modalDelete = false;
    $scope.modalDeleteForm = false;
    $scope.peCLaimsList = [];
    $scope.qrfCLaimsList = [];
    $scope.qrfclaims = [];
    $scope.peclaims = [];
    $scope.claimOverviewList = [];
    $scope.selectedQRFCount = 0;
    $scope.selectedPECount = 0;
    $scope.qrfClaimPayment = {};
    $scope.AddBusinessDays = function (weekDaysToAdd) {
      var actualDaysToAdd = weekDaysToAdd;
      var curdate = new Date();
      while (weekDaysToAdd >= 0) {
        var d = curdate.getDay();
        if (d === 0 || d === 6) {
          actualDaysToAdd++;
        } else {
          weekDaysToAdd--;
        }
        curdate.setDate(curdate.getDate() + 1);
      }
      return actualDaysToAdd;
    };
    $scope.qrfClaimPayment.paymentDate = new Date().setDate(
      new Date().getDate() + $scope.AddBusinessDays(3)
    );
    $scope.modalLeftButton = $filter('translate')('label_confirm');
    $scope.modalRightButton = $filter('translate')('label_cancel');
    $scope.modalHeader = $filter('translate')('label_modalConfirmAction');

    //condition to get status list based on workstation
    if ($scope.userType === 'AG' && $scope.eventStatus === 'L') {
      $scope.statusList = [
        {
          statusValue: 'All',
          statusCode: 'ALL',
        },
        {
          statusValue: 'To be authorized',
          statusCode: 'TBA',
        },
        {
          statusValue: 'Sent to issuer',
          statusCode: 'STI',
        },
        {
          statusValue: 'Submitted',
          statusCode: 'SUB',
        },
        {
          statusValue: 'Rejected',
          statusCode: 'REJ',
        },
        {
          statusValue: 'Payment requested',
          statusCode: 'PRQ',
        },
        {
          statusValue: 'Payment received',
          statusCode: 'PRC',
        },
        {
          statusValue: 'Payment completed',
          statusCode: 'PCM',
        },
      ];
    } else if ($scope.userType === 'IN' && $scope.eventStatus === 'L') {
      $scope.statusList = [
        {
          statusValue: 'All',
          statusCode: 'ALL',
        },
        {
          statusValue: 'To be authorized',
          statusCode: 'TBA',
        },
        {
          statusValue: 'Sent to issuer',
          statusCode: 'STI',
        },
        {
          statusValue: 'Submitted',
          statusCode: 'SUB',
        },
        {
          statusValue: 'Rejected',
          statusCode: 'REJ',
        },
        {
          statusValue: 'Payment completed',
          statusCode: 'PCM',
        },
      ];
    } else if ($scope.userType === 'IS' && $scope.eventStatus === 'L') {
      $scope.statusList = [
        {
          statusValue: 'All',
          statusCode: 'ALL',
        },
        {
          statusValue: 'Received',
          statusCode: 'STI',
        },
        {
          statusValue: 'Submitted',
          statusCode: 'SUB',
        },
        {
          statusValue: 'Rejected',
          statusCode: 'REJ',
        },
        {
          statusValue: 'Payment completed',
          statusCode: 'PCM',
        },
      ];
    } else {
      $scope.statusList = [
        {
          statusValue: 'All',
          statusCode: 'ALL',
        },
        {
          statusValue: 'Rejected',
          statusCode: 'REJ',
        },
        {
          statusValue: 'Payment completed',
          statusCode: 'PCM',
        },
      ];
    }

    //below is the function call to get more claims
    $scope.getMoreClaims = function () {
      $scope.loading = true;
      ViewDwtDetailService.getDWTClaimDetailsOverview(
        $sessionStorage.groupId,
        $scope.eventId,
        $scope.userType,
        $scope.pageNo
      ).then(
        function (response) {
          $scope.viewDwtClaimDetailModal = new viewDwtClaimDetailModal(
            response.data,
            $scope.userType,
            $scope.pageNo
          );
          $scope.claimOverviewList =
            $scope.viewDwtClaimDetailModal.claimOverviewList;
          if (!$scope.qrfclaims) {
            $scope.qrfclaims = $scope.viewDwtClaimDetailModal.qrfclaims;
          } else if ($scope.viewDwtClaimDetailModal.qrfclaims) {
            $scope.qrfclaims = $scope.qrfclaims.concat(
              $scope.viewDwtClaimDetailModal.qrfclaims
            );
          }
          if (!$scope.peclaims) {
            $scope.peclaims = $scope.viewDwtClaimDetailModal.peclaims;
          } else if (
            $scope.viewDwtClaimDetailModal.peclaims &&
            $scope.peclaims
          ) {
            $scope.peclaims = $scope.peclaims.concat(
              $scope.viewDwtClaimDetailModal.peclaims
            );
          }
          $scope.onStatusChange($scope.selectedStatus);
          $scope.loading = false;
        },
        function (error) {
          $scope.loading = false;
          $log.debug(error);
        }
      );
    };
    // below is the function call for PE claims table pagination
    $scope.setPEPage = function (page) {
      $scope.pageNoForPE = page;
      $sessionStorage.pePage = page;
      $scope.pePager = [];
      if (page < 1 || page > $scope.pePager.totalPages) {
        return;
      }
      // get pager object from service
      $scope.pePager = PaginationSvc.GetPager($scope.dummyPEItems.length, page);
      // get current page of items
      $scope.peItems = $scope.dummyPEItems.slice(
        $scope.pePager.startIndex,
        $scope.pePager.endIndex + 1
      );
      if (
        $scope.peItems.length <= 10 &&
        ($scope.totalQRFCount + $scope.totalPECount) / 500 > 1
      ) {
        $scope.pageNo = $scope.pageNo + 1;
        if (
          $scope.pageNo <=
          Math.ceil(($scope.totalQRFCount + $scope.totalPECount) / 500)
        ) {
          $scope.getMoreClaims();
        }
      }
    };

    // below is the function call for QRF claims table pagination
    $scope.setQRFPage = function (page) {
      $scope.pageNoFoRQRF = page;
      $sessionStorage.qrfPage = page;
      $scope.qrfPager = [];
      if (page < 1 || page > $scope.qrfPager.totalPages) {
        return;
      }
      // get pager object from service
      $scope.qrfPager = PaginationSvc.GetPager(
        $scope.dummyQRFItems.length,
        page
      );
      // get current page of items
      $scope.qrfItems = $scope.dummyQRFItems.slice(
        $scope.qrfPager.startIndex,
        $scope.qrfPager.endIndex + 1
      );
      if (
        $scope.qrfItems.length <= 10 &&
        ($scope.totalQRFCount + $scope.totalPECount) / 500 > 1
      ) {
        $scope.pageNo = $scope.pageNo + 1;
        if (
          $scope.pageNo <=
          Math.ceil(($scope.totalQRFCount + $scope.totalPECount) / 500)
        ) {
          $scope.getMoreClaims();
        }
      }
    };

    //function call to get the table header based on the status
    $scope.getDateByStatus = function () {
      const statusDateMap = {
        'ALL': 'label_createdDate',
        'PRQ': 'label_paymentRequestedDate',
        'PRC': 'label_paymentReceivedDate',
        'PCM': 'label_paymentCompletedDate',
        'SUB': 'label_submittedDate',
        'REJ': 'label_rejectedDate',
        'TBA': $scope.userType !== 'IN' ? 'label_tobeAuthoriseDate' : 'label_createdDate',
        'STI': $scope.userType === 'IS' ? 'label_recievedDate' : 'label_sentToIssuerDate'
      };

      return $filter('translate')(statusDateMap[$scope.status] || 'label_createdDate');
    };

    //function call on change of status
    $scope.onStatusChange = function (selectedStatus) {
      $sessionStorage.selectedClaimStatus = selectedStatus;
      if ($sessionStorage.selectedClaimStatus.length > 0) {
        $scope.status = $sessionStorage.selectedClaimStatus[0].statusCode;
      }
      if ($scope.status !== 'ALL') {
        $scope.peCLaimsList = $filter('filter')(
          $scope.peclaims,
          { status: $scope.status },
          true
        );
        if ($scope.peCLaimsList) {
          $scope.dummyPEItems = $scope.peCLaimsList;
          $scope.pager = {};
          if ($sessionStorage.pePage) {
            $scope.setPEPage($sessionStorage.pePage);
          } else {
            $scope.setPEPage(1);
          }
        }
        $scope.qrfCLaimsList = $filter('filter')(
          $scope.qrfclaims,
          { status: $scope.status },
          true
        );
        if ($scope.qrfCLaimsList) {
          $scope.dummyQRFItems = $scope.qrfCLaimsList;
          $scope.qrfPager = {};
          if ($sessionStorage.qrfPage) {
            $scope.setQRFPage($sessionStorage.qrfPage);
          } else {
            $scope.setQRFPage(1);
          }
        }
      } else {
        $scope.peCLaimsList = $scope.peclaims;
        $scope.qrfCLaimsList = $scope.qrfclaims;
        if ($scope.peCLaimsList) {
          $scope.dummyPEItems = $scope.peCLaimsList;
          $scope.pager = {};
          if ($sessionStorage.pePage) {
            $scope.setPEPage($sessionStorage.pePage);
          } else {
            $scope.setPEPage(1);
          }
        }
        if ($scope.qrfCLaimsList) {
          $scope.dummyQRFItems = $scope.qrfCLaimsList;
          $scope.qrfPager = {};
          if ($sessionStorage.qrfPage) {
            $scope.setQRFPage($sessionStorage.qrfPage);
          } else {
            $scope.setQRFPage(1);
          }
        }
      }
      $scope.tableDate = $scope.getDateByStatus();
    };

    //function call to invoke the service to fetch the view claim details
    $scope.getViewClaimDetails = function (pageNo) {
      if (null !== $scope.eventId) {
        $scope.loading = true;
        ViewDwtDetailService.getDWTClaimDetailsOverview(
          $sessionStorage.groupId,
          $scope.eventId,
          $scope.userType,
          pageNo
        ).then(
          function (response) {
            $scope.viewDwtClaimDetailModal = new viewDwtClaimDetailModal(
              response.data,
              $scope.userType,
              pageNo
            );
            $scope.claimOverviewList =
              $scope.viewDwtClaimDetailModal.claimOverviewList;
            $scope.qrfclaims = $scope.viewDwtClaimDetailModal.qrfclaims;
            $scope.peclaims = $scope.viewDwtClaimDetailModal.peclaims;
            var keepGoing = true;
            var defaultStatus = null;
            var gdefaultStatus = [];
            var count = 0;
            if (keepGoing) {
              angular.forEach($scope.claimOverviewList, function (value, key) {
                count++;
                if ($scope.claimOverviewList[key].peclaimsCount) {
                  var peToBeAuthorised =
                    $scope.claimOverviewList[key].peclaimsCount.toBeAuthorised;
                  var peSentToIssuer =
                    $scope.claimOverviewList[key].peclaimsCount.sentToIssuer;
                  var peSubmitted =
                    $scope.claimOverviewList[key].peclaimsCount.submitted;
                  var peRejected =
                    $scope.claimOverviewList[key].peclaimsCount.rejected;
                  var pePaymentRequested =
                    $scope.claimOverviewList[key].peclaimsCount
                      .paymentRequested;
                  var pePaymentReceived =
                    $scope.claimOverviewList[key].peclaimsCount.paymentReceived;
                  var pePaymentCompleted =
                    $scope.claimOverviewList[key].peclaimsCount
                      .paymentCompleted;
                  var totalPECount =
                    $scope.claimOverviewList[key].peclaimsCount.total;
                }
                if ($scope.claimOverviewList[key].qrfclaimsCount) {
                  var qrfToBeAuthorised =
                    $scope.claimOverviewList[key].qrfclaimsCount.toBeAuthorised;
                  var qrfSentToIssuer =
                    $scope.claimOverviewList[key].qrfclaimsCount.sentToIssuer;
                  var qrfSubmitted =
                    $scope.claimOverviewList[key].qrfclaimsCount.submitted;
                  var qrfRejected =
                    $scope.claimOverviewList[key].qrfclaimsCount.rejected;
                  var qrfPaymentRequested =
                    $scope.claimOverviewList[key].qrfclaimsCount
                      .paymentRequested;
                  var qrfPaymentReceived =
                    $scope.claimOverviewList[key].qrfclaimsCount
                      .paymentReceived;
                  var qrfPaymentCompleted =
                    $scope.claimOverviewList[key].qrfclaimsCount
                      .paymentCompleted;
                  var totalQRFCount =
                    $scope.claimOverviewList[key].qrfclaimsCount.total;
                }
                if ($scope.totalPECount === 0 && totalPECount > 0) {
                  $scope.totalPECount = totalPECount;
                } else if (totalPECount) {
                  $scope.totalPECount = $scope.totalPECount + totalPECount;
                }
                if ($scope.totalQRFCount === 0 && totalQRFCount > 0) {
                  $scope.totalQRFCount = totalQRFCount;
                } else if (totalQRFCount) {
                  $scope.totalQRFCount = $scope.totalQRFCount + totalQRFCount;
                }
                if (
                  (peToBeAuthorised || qrfToBeAuthorised) > 0 &&
                  $scope.userType !== 'IS'
                ) {
                  defaultStatus = 'TBA';
                  keepGoing = false;
                  count = 1;
                } else if ((peSentToIssuer || qrfSentToIssuer) > 0) {
                  defaultStatus = 'STI';
                  keepGoing = false;
                  count = 2;
                } else if ((peSubmitted || qrfSubmitted) > 0) {
                  defaultStatus = 'SUB';
                  keepGoing = false;
                  count = 3;
                } else if ((peRejected || qrfRejected) > 0) {
                  defaultStatus = 'REJ';
                  keepGoing = false;
                  count = 4;
                } else if (
                  (pePaymentRequested || qrfPaymentRequested) > 0 &&
                  $scope.userType === 'AG'
                ) {
                  defaultStatus = 'PRQ';
                  keepGoing = false;
                  count = 5;
                } else if (
                  (pePaymentReceived || qrfPaymentReceived) > 0 &&
                  $scope.userType === 'AG'
                ) {
                  defaultStatus = 'PRC';
                  keepGoing = false;
                  count = 6;
                } else if ((pePaymentCompleted || qrfPaymentCompleted) > 0) {
                  defaultStatus = 'PCM';
                  keepGoing = false;
                  count = 7;
                } else {
                  defaultStatus = 'ALL';
                  keepGoing = false;
                  count = 8;
                }
                gdefaultStatus.push({
                  count: count,
                  defaultStatus: defaultStatus,
                });
              });
            }
            var sortedStatusArray = $filter('orderBy')(
              gdefaultStatus,
              'count',
              false
            );
            if (sortedStatusArray.length > 0) {
              $scope.selectedStatus = $filter('filter')(
                $scope.statusList,
                {
                  statusCode: sortedStatusArray[0].defaultStatus,
                },
                true
              );
            } else {
              $scope.selectedStatus = $filter('filter')(
                $scope.statusList,
                {
                  statusCode: 'ALL',
                },
                true
              );
            }
            if ($sessionStorage.selectedClaimStatus) {
              $scope.selectedStatus = $filter('filter')(
                $scope.statusList,
                {
                  statusCode: $sessionStorage.selectedClaimStatus[0].statusCode,
                },
                true
              );
            } else {
              $sessionStorage.selectedClaimStatus = $scope.selectedStatus;
            }
            $scope.onStatusChange($scope.selectedStatus);
            if ($scope.navFrom === 'qrfClaim') {
              $location.hash('qrfClaim');
              $anchorScroll();
            } else if ($scope.navFrom === 'peClaim') {
              $location.hash('peClaim');
              $anchorScroll();
            }
            if (
              $scope.totalQRFCount + $scope.totalPECount < 500 ||
              !($scope.totalQRFCount + $scope.totalPECount)
            ) {
              $scope.loading = false;
            }
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
            $log.debug(error);
          }
        );
      }
    };

    //function call to invoke service on load for the first time
    $scope.getViewClaimDetails($scope.pageNo);


    //function call to check all the PE claims which are in sent to issuer status
    $scope.checkAllPE = function () {
      angular.forEach($scope.peCLaimsList, function (peClaim) {
        peClaim.select = $scope.select.allPE;
        if (peClaim.select === true) {
          $scope.selectedPECount = $scope.peCLaimsList.length;
        } else {
          $scope.selectedPECount = 0;
        }
      });
    };

    //function call to check the particular PE claims which are in sent to issuer status
    $scope.selectePEClaim = function () {
      $scope.selectAllPE = false;
      var count = 0;
      angular.forEach($scope.peCLaimsList, function (peClaim) {
        if (peClaim.select === true) {
          count++;
        }
      });
      $scope.selectedPECount = count;
    };

    //function call to check all the QRF claims which are in sent to issuer status
    $scope.checkAllQRF = function () {
      angular.forEach($scope.qrfCLaimsList, function (qrfClaim) {
        qrfClaim.select = $scope.selectAllQRF;
        if (qrfClaim.select === true) {
          $scope.selectedQRFCount = $scope.qrfCLaimsList.length;
        } else {
          $scope.selectedQRFCount = 0;
        }
      });
    };

    //function call to check all the particular claims which are in sent to issuer status
    $scope.selecteQRFClaim = function () {
      $scope.selectAllQRF = false;
      var count = 0;
      angular.forEach($scope.qrfCLaimsList, function (qrfClaim) {
        if (qrfClaim.select === true) {
          count++;
        }
      });
      $scope.selectedQRFCount = count;
    };

    //function call to submit all PE claims which are in sent to issuer status
    $scope.submitAllPeClaims = function () {
      $scope.select.allPE = true;
      $scope.modalPEClaim = true;
      $scope.modalBody =
        $filter('translate')('label_areYouSureModal') +
        $filter('translate')('label_submitAllPEClaims') +
        $filter('translate')('label_questionMark');
      $scope.checkAllPE();
      $scope.peClaimIdArray = [];
      angular.forEach($scope.peCLaimsList, function (peClaim) {
        $scope.peClaimIdArray.push({
          claimId: peClaim.claimId,
          intermediaryId: peClaim.intermediaryId,
          ownerName: peClaim.beneficalOwnerName,
        });
      });
    };

    //function call to submit all selected PE claims which are in sent to issuer status
    $scope.submitSelectedPeClaims = function () {
      $scope.peClaimIdArray = [];
      $scope.modalPEClaim = true;
      $scope.modalBody =
        $filter('translate')('label_areYouSureModal') +
        $filter('translate')('label_submitSelectedPEClaims') +
        $filter('translate')('label_questionMark');
      angular.forEach($scope.peCLaimsList, function (peClaim) {
        if (peClaim.select === true) {
          $scope.peClaimIdArray.push({
            claimId: peClaim.claimId,
            intermediaryId: peClaim.intermediaryId,
            ownerName: peClaim.beneficalOwnerName,
          });
        }
      });
    };

    //function call to submit all QRF claims which are in sent to issuer status
    $scope.submitAllQrfClaims = function () {
      $scope.selectAllQRF = true;
      $scope.modalQRFClaim = true;
      $scope.modalBody =
        $filter('translate')('label_areYouSureModal') +
        $filter('translate')('label_submitAllQRFClaims') +
        $filter('translate')('label_questionMark');
      $scope.checkAllQRF();
      $scope.qrfClaimIdArray = [];
      angular.forEach($scope.qrfCLaimsList, function (qrfClaim) {
        $scope.qrfClaimIdArray.push({
          claimId: qrfClaim.claimId,
          intermediaryId: qrfClaim.intermediaryId,
          ownerName: qrfClaim.beneficalOwnerName,
        });
      });
    };

    //function call to submit all selected QRF claims which are in sent to issuer status
    $scope.submitSelectedQrfClaims = function () {
      $scope.qrfClaimIdArray = [];
      $scope.modalQRFClaim = true;
      $scope.modalBody =
        $filter('translate')('label_areYouSureModal') +
        $filter('translate')('label_submitSelectedQRFClaims') +
        $filter('translate')('label_questionMark');
      angular.forEach($scope.qrfCLaimsList, function (qrfClaim) {
        if (qrfClaim.select === true) {
          $scope.qrfClaimIdArray.push({
            claimId: qrfClaim.claimId,
            intermediaryId: qrfClaim.intermediaryId,
            ownerName: qrfClaim.beneficalOwnerName,
          });
        }
      });
    };

    //function call to invoke the submit claim service
    $scope.submitClaims = function (claimsIdList) {
      $scope.loading = true;
      var claimList = {
        claimIntermediaryIDVOs: claimsIdList,
        eventId: $scope.eventId,
        issuerName: $scope.issuerName,
        issuerId: $scope.issuerId,
        userId: $scope.userId,
        userGroupId: userGroupId,
        userType: $scope.userType,
        eventName: $scope.eventName,
      };
      $scope.loading = true;
      ViewDwtDetailService.submitClaims(claimList, 'secureToken').then(
        function (response) {
          $scope.response = response.data.message;
          $scope.selectAllQRF = false;
          $scope.select.allPE = false;
          $scope.modalPEClaim = false;
          $scope.modalQRFClaim = false;
          $scope.selectedQRFCount = 0;
          $scope.selectedPECount = 0;
          $scope.loading = false;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $scope.pageNo = 1;
            $scope.totalQRFCount = 0;
            $scope.totalPECount = 0;
            $scope.getViewClaimDetails($scope.pageNo);
            $('.modal-backdrop').remove();
          }, 2000);
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.errors[0].errorMessage;
        }
      );
    };

    //function call to download intermediary confirmation form
    $scope.downloadIntermediaryConfirmation = function () {
      var downloadUrl = 'v1/taxreclaim/intermediaryconfirmation';
      var a = document.createElement('a');
      document.body.appendChild(a);
      $scope.loading = true;
      dwtFileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
          $scope.loading = false;
        },
        function (error) {
          $scope.loading = false;
          $log.debug(error);
        }
      );
    };

    //function call to update the modal window details on click of undo submit or undo reject
    $scope.onOptionClick = function (claimId, status) {
      $scope.modalSubRej = true;
      $scope.undoStatus = status;
      $scope.undoClaimId = claimId;
      if ($scope.undoStatus === 'REJ') {
        var action = $filter('translate')('lable_undoRejectModal');
      } else {
        action = $filter('translate')('lable_undoSubmitModal');
      }
      $scope.modalBody = $filter('translate')('label_areYouSureModal') + action;
    };

    //function call on click of confirm in modal window  to invoke undo submit or undo reject service
    $scope.undoSubmitOrReject = function (claimId, status) {
      $scope.loading = true;
      var undoData = {
        claimIdForUndo: claimId,
        eventId: $scope.eventId,
        userType: $scope.userType,
        statusFlag: status,
      };
      ViewDwtDetailService.undoSubmitOrReject(undoData, 'secureToken').then(
        function (response) {
          $scope.response = response.data.message;
          $scope.modalSubRej = false;
          $scope.loading = false;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $scope.pageNo = 1;
            $scope.totalQRFCount = 0;
            $scope.totalPECount = 0;
            $scope.getViewClaimDetails($scope.pageNo);
            $('.modal-backdrop').remove();
          }, 2000);
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.message;
          $('#errorMsgModal').modal('show');
        }
      );
    };

    //function call on click of delete icon to update modal window
    $scope.deleteModal = function (claimId) {
      $scope.deleteClaimId = claimId;
      $scope.modalDelete = true;
      $scope.modalBody = $filter('translate')('label_modalDeleteBody');
    };

    //function call on click of confirm in modal window  to invoke delete service
    $scope.deleteClaim = function (claimId) {
      $scope.loading = true;
      ViewDwtDetailService.deleteClaim(
        $scope.eventId,
        claimId,
        'secureToken'
      ).then(
        function (response) {
          $scope.response = response.data.message;
          $scope.modalDelete = false;
          $scope.loading = false;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $scope.pageNo = 1;
            $scope.totalQRFCount = 0;
            $scope.totalPECount = 0;
            $scope.getViewClaimDetails($scope.pageNo);
            $('.modal-backdrop').remove();
          }, 2000);
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.errors[0].errorMessage;
        }
      );
    };

    //upload 6166 and IB92/96 forms
    $scope.addEditClaimForms = function (e, qrfClaim, formType) {
      $scope.fileList = e.files;
      if (
        'application/pdf' !== $scope.fileList[0].type &&
        'application/softgrid-pdf' !== $scope.fileList[0].type
      ) {
        qrfClaim.error = true;
        $timeout(function () {
          qrfClaim.error = false;
        }, 5000);
      } else if ($scope.fileList[0].name.length > 50) {
        qrfClaim.fileNameError = true;
        $timeout(function () {
          qrfClaim.fileNameError = false;
        }, 5000);
      } else {
        var startDate = new Date();
        var endDate = new Date(startDate.getFullYear(), 11, 31);
        endDate.setHours(23, 59, 59);
        var documentFormData = new FormData();
        documentFormData.append('claimId', qrfClaim.claimId);
        if (formType === 'FT_6166') {
          if (qrfClaim.form6166Id) {
            var formId = qrfClaim.form6166Id;
            documentFormData.append('formId', formId);
          }
        } else {
          if (qrfClaim.formIB9296Id) {
            formId = qrfClaim.formIB9296Id;
            documentFormData.append('formId', formId);
          }
        }
        documentFormData.append('loginUserId', $sessionStorage.userId);
        documentFormData.append('File', $scope.fileList[0]);
        documentFormData.append('formType', formType);
        if (formType === 'FT_6166') {
          documentFormData.append('shareholderId', qrfClaim.shareholderId);
          documentFormData.append(
            'beneficialName',
            qrfClaim.beneficalOwnerName
          );
          documentFormData.append('eventName', $scope.eventName);
          documentFormData.append(
            'startDate',
            $filter('date')(startDate, 'yyyy-MM-dd HH:mm:ss')
          );
          documentFormData.append(
            'endDate',
            $filter('date')(endDate, 'yyyy-MM-dd HH:mm:ss')
          );
          documentFormData.append('eventId', $scope.eventId);
        }
        var file = $scope.fileList;
        var uploadUrl = 'v1/claimforms/upload';
        $scope.loading = true;
        fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
          function (data) {
            $scope.response = data.data.message;
            $scope.loading = false;
            if (data.status != 200 && data.status != 201 && data.data.errors) {
              ModelWindowService.showModelWindow('downloadError');
              $scope.downloadErrorConfig.modalHeader = 'Unable to upload !';
              $scope.downloadErrorConfig.modalBody =
                data.data.errors[0].errorMessage;
            } else {
              $('#successMsg').modal('show');
              $timeout(function () {
                $scope.pageNo = 1;
                $scope.totalQRFCount = 0;
                $scope.totalPECount = 0;
                $scope.getViewClaimDetails($scope.pageNo);
                $('#successMsg').modal('hide');
                $('.modal-backdrop').remove();
              }, 2000);
            }
          },
          function (error) {
            $scope.loading = false;
            $log.debug(error);
          }
        );
      }
    };

    //function to download 6166Form or IB92/96 Form
    $scope.downloadClaimForms = function (formType, formId) {
      $scope.loading = true;
      var downloadUrl =
        'v1/claimforms/' + formId + '/' + formType + '/download';
      var a = document.createElement('a');
      document.body.appendChild(a);
      dwtFileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
          $scope.loading = false;
        },
        function (error) {
          $scope.loading = false;
          $log.debug(error);
        }
      );
    };

    //function call on click of delete icon of 6166 or IB 92/96 form to update modal window
    $scope.updateDeleteFormModal = function (formId, formType, index) {
      $scope.index = index;
      $scope.modalDeleteForm = true;
      $scope.deleteFormId = formId;
      $scope.deleteFormType = formType;
      if (formType === 'FT_6166') {
        $scope.modalBody = $filter('translate')(
          'label_modalDeleteOtherFormBody'
        );
      } else {
        $scope.modalBody = $filter('translate')('label_modalDeleteIbFormBody');
      }
    };

    //function call on click of confirm in modal window  to invoke delete service
    $scope.deleteClaimForm = function (formId, formType) {
      $scope.loading = true;
      ViewDwtDetailService.deleteClaimForm(
        formId,
        formType,
        'secureToken'
      ).then(
        function (response) {
          var index = '#otherUpload_' + $scope.index;
          $(index).val('');
          $scope.response = response.data.message;
          $scope.modalDeleteForm = false;
          $scope.loading = false;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $scope.pageNo = 1;
            $scope.totalQRFCount = 0;
            $scope.totalPECount = 0;
            $scope.getViewClaimDetails($scope.pageNo);
            $('.modal-backdrop').remove();
          }, 2000);
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.errors[0].errorMessage;
        }
      );
    };

    /* date picker */
    $scope.inlineOptions = {
      customClass: getDayClass,
    };
    function disabled(data) {
      var date, mode;
      date = data.date;
      mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }
    $scope.dateOptions = {
      dateDisabled: disabled,
      formatYear: 'yy',
      maxDate: new Date(2999, 5, 22),
      minDate: new Date(),
      showWeeks: false,
      disableWeekends: true,
    };
    $scope.open1 = function () {
      $scope.popup1.opened = true;
    };
    $scope.setDate = function (year, month, day) {
      $scope.dt = new Date(year, month, day);
    };
    $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];
    $scope.altInputFormats = ['M!/d!/yyyy'];
    $scope.popup1 = {
      opened: false,
    };
    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

        for (var i = 0; i < $scope.events.length; i++) {
          var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);
          if (dayToCheck === currentDay) {
            return $scope.events[i].status;
          }
        }
      }
      return '';
    }
    /* date picker end */
    // function to invoke request payment service
    $scope.requestPaymentFunction = function (requestPaymentData) {
      $scope.loading = true;
      ViewDwtDetailService.requestQrfPayment(
        $scope.eventId,
        requestPaymentData
      ).then(
        function (response) {
          $scope.$emit('requestQRFPaymentSuccess');
          $scope.response = response.data.message;
          $scope.loading = false;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $state.go('dwtDetails.dwtPaymentDetails');
            $('.modal-backdrop').remove();
          }, 2000);
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.errors[0].errorMessage;
        }
      );
    };

    //function call on click of request payment button
    $scope.requestQrfPayment = function () {
      let openModal = false;
      angular.forEach(
        $scope.viewDwtClaimDetailModal.claimOverviewList,
        function (qrfClaim) {
          if (
            qrfClaim.qrfclaimsCount &&
            (qrfClaim.qrfclaimsCount.toBeAuthorised > 0 ||
              qrfClaim.qrfclaimsCount.sentToIssuer > 0)
          ) {
            openModal = true;
          }
        }
      );
      $scope.requestPaymentData = {
        eventId: $scope.eventId,
        paymentRequestDate: $filter('date')(
          $scope.qrfClaimPayment.paymentDate,
          'yyyy-MM-dd 00:00:00'
        ),
      };
      if (openModal) {
        $scope.modalLeftButton = $filter('translate')('label_cancel');
        $scope.modalRightButton = $filter('translate')('lable_reqPaymentButton');
        $scope.modalBody = $filter('translate')('label_requestPaymentModal');
        ModelWindowService.showModelWindow('alertBox');
      } else {
        $scope.requestPaymentFunction($scope.requestPaymentData);
      }
    };

    //below is the function call to filter the claims based on claimId    ,'ownerName':qrfClaim.beneficalOwnerName
    $scope.onCLaimSearch = function (ownerName) {
      $scope.dummyQRFItems = $filter('filter')($scope.qrfCLaimsList, {
        beneficalOwnerName: ownerName,
      });
      $scope.dummyPEItems = $filter('filter')($scope.peCLaimsList, {
        beneficalOwnerName: ownerName,
      });
      $scope.qrfPager = {};
      $scope.setQRFPage(1);
      $scope.pager = {};
      $scope.setPEPage(1);
    };

    //function to download PDF report
    $scope.downloadPdfReport = function (status) {
      $scope.loading = true;
      if (!$scope.viewDwtClaimDetailModal.claimOverviewList) {
        $scope.downloadErrorConfig.modalHeader = $filter('translate')(
          'label_unableToDownload'
        );
        $scope.downloadErrorConfig.modalBody = $filter('translate')(
          'label_noClaims'
        );
        ModelWindowService.showModelWindow('downloadError');
        $scope.loading = false;
      } else {
        var downloadUrl =
          'v1/dwtclaims/' +
          $scope.eventId +
          '/' +
          status +
          '/claims/reports/pdf';
        var a = document.createElement('a');
        document.body.appendChild(a);
        dwtFileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
            $scope.loading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.loading = false;
          }
        );
      }
      $scope.loading = false;
    };

    //function to download EXCEL report
    $scope.downloadExcelReport = function (status) {
      $scope.loading = true;
      if (!$scope.viewDwtClaimDetailModal.claimOverviewList) {
        $scope.downloadErrorConfig.modalHeader = $filter('translate')(
          'label_unableToDownload'
        );
        $scope.downloadErrorConfig.modalBody = $filter('translate')(
          'label_noClaims'
        );
        ModelWindowService.showModelWindow('downloadError');
        $scope.loading = false;
      } else {
        var downloadUrl =
          'v1/dwtclaims/' +
          $scope.eventId +
          '/' +
          status +
          '/claims/reports/excel';
        var a = document.createElement('a');
        document.body.appendChild(a);
        dwtFileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
            $scope.loading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.loading = false;
          }
        );
      }
    };
  },
]);
