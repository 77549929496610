/**
 * @ngdoc controller
 * @name dwtModule.DwtPaymentDetailsController
 */
'use strict';

angular.module('dwtModule').controller('DwtPaymentDetailsController', [
  '$scope',
  '$log',
  'ViewTaxReclaimModal',
  'ViewDwtDetailService',
  '$sessionStorage',
  '$timeout',
  'fileDownloadSvc',
  'cbpDownloadBlob',
  function (
    $scope,
    $log,
    ViewTaxReclaimModal,
    ViewDwtDetailService,
    $sessionStorage,
    $timeout,
    fileDownloadSvc,
    cbpDownloadBlob
  ) {
    $scope.userId = $sessionStorage.userId;
    $scope.userType = $sessionStorage.usertype;
    $scope.loading = false;

    function flashSuccess(msg) {
      $scope.paymentSuccess = msg;
      $('#successMsg').modal('show');
      $timeout(function() {
        $('#successMsg').modal('hide');
        $('.modal-backdrop').remove();
      }, 2000);
    }

    // To get data for payment detail summary
    $scope.getPaymentDetails = function () {
      ViewDwtDetailService.getPaymentDetailsOverview($scope.eventId)
        .then(function (data) {
          $scope.paymentDetailsOverview = data;
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    $scope.getQrfPaymentDetails = function () {
      ViewDwtDetailService.getQrfPaymentDetails($scope.eventId)
        .then(function (resp) {
          $scope.qrfDetails = resp.data.list;
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    $scope.pePaymentCompleted = function (paymentClaimId) {
      const requestBody = {
        eventId: $scope.eventId,
        dwtClaims: [{ claimId: paymentClaimId }],
      };
      ViewDwtDetailService.pePaymentCompletedAction(requestBody).then(
        function (data) {
          flashSuccess(data.data.message);
          $scope.getPaymentDetails();
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    $scope.qrfPaymentCompleted = function (invoiceId, intermediaryId) {
      ViewDwtDetailService.qrfPaymentCompletedAction(
        $scope.eventId,
        invoiceId,
        intermediaryId
      ).then(
        function (data) {
          flashSuccess('Payment to intermediary has been made successfully');
          $scope.getQrfPaymentDetails();
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    $scope.pePaymentReceived = function (paymentClaimId) {
      const requestData = {
        eventId: $scope.eventId,
        dwtClaims: [{ claimId: paymentClaimId }],
      };
      ViewDwtDetailService.pePaymentReceivedAction(requestData).then(
        function (data) {
          flashSuccess(data.data.message);
          $scope.getPaymentDetails();
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    $scope.qrfPaymentReceived = function (invoiceId, intermediaryId) {
      ViewDwtDetailService.qrfPaymentReceivedAction(
        $scope.eventId,
        invoiceId,
        intermediaryId
      ).then(
        function (data) {
          flashSuccess('Payment received successfully');
          $scope.getQrfPaymentDetails();
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    // Below is the function call from HTML to download pdf
    $scope.downloadInvoicePDF = function (taxInvoiceId) {
      const downloadUrl = `v1/claim-invoices/${taxInvoiceId}/invoice-pdf`;
      $scope.loading = true;
      fileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          cbpDownloadBlob(response.file, response.fileName);
          $scope.loading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.loading = false;
        }
      );
    };

    //function to download EXCEL report for ref number.
    $scope.downloadRefExcelReport = function (refNumber, event, str) {
      $scope.loading = true;
      const downloadUrl =
        `v1/dwtpayments/${$scope.eventId}/${refNumber}/${str}/refNumberExcel`;
      fileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          cbpDownloadBlob(response.file, response.fileName);
          $scope.loading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.loading = false;
        }
      );
    };

    $scope.downloadAllRasInvoicesExcel = function () {
      $scope.loading = true;
      const downloadUrl = `/v1/dwtpayments/${$scope.eventId}/qrfPaymentExcel`;
      fileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          cbpDownloadBlob(response.file, response.fileName);
          $scope.loading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.loading = false;
        }
      );
    };

    $scope.downloadInvoiceExcel = function (invoiceId) {
      $scope.loading = true;
      const downloadUrl = `/v1/dwtpayments/dwt-events/${$scope.eventId}/invoices/${invoiceId}/qrf-payment-excel`;
      fileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          cbpDownloadBlob(response.file, response.fileName);
          $scope.loading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.loading = false;
        }
      );
    };

    $scope.downloadInvoiceByIntermediaryExcel = function (invoiceId, intermediaryId) {
      $scope.loading = true;
      const downloadUrl = `/v1/dwtpayments/dwt-events/${$scope.eventId}/invoices/` +
        `${invoiceId}/qrf-payment-excel?intermediaryId=${intermediaryId}`;
      fileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          cbpDownloadBlob(response.file, response.fileName);
          $scope.loading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.loading = false;
        }
      );
    };

    // call first time
    $scope.getPaymentDetails();
    $scope.getQrfPaymentDetails();
  },
]);
