/**
 * @ngDoc controller
 * @name Tax reclaimHomeModule.Tax reclaimController
 */
angular.module('dwtModule').controller('DwtLandingController', [
  '$scope',
  '$sessionStorage',
  '$state',
  '$log',
  'ExtensionRequestService',
  'ModelWindowService',
  'CreateDwtExtensionReqService',
  '$filter',
  'createEditDwtService',
  'DwtHomeService',
  'fileDownloadSvc',
  'cbpDownloadBlob',
  '$timeout',
  '$sanitize',
  function (
    $scope,
    $sessionStorage,
    $state,
    $log,
    ExtensionRequestService,
    ModelWindowService,
    CreateDwtExtensionReqService,
    $filter,
    createEditDwtService,
    DwtHomeService,
    fileDownloadSvc,
    cbpDownloadBlob,
    $timeout,
    $sanitize
  ) {
    $sessionStorage.productType = 'DWT';
    $scope.userType = $sessionStorage.usertype;
    $scope.agentName = $sessionStorage.userName;
    $scope.userId = $sessionStorage.userId;
    $scope.userName = $sessionStorage.userName;
    $scope.groupId = $sessionStorage.groupId;
    $sessionStorage.isAdmin = $sessionStorage.isAdminDWT;
    $scope.isAdmin = $sessionStorage.isAdminDWT;
    $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;
    $scope.productType = 'DWT';
    $scope.langCode = 'EN';
    $scope.loading = true;
    $scope.loadingDownload = false;
    $scope.dwtHomeData = [];
    $scope.issuerName = '';
    $scope.modalBody = $filter('translate')('areSureDelete_modal');
    $scope.modalLeftButton = $filter('translate')('label_confirm');
    $scope.modalRightButton = $filter('translate')('label_cancel');
    $scope.issuerSearch = {};
    $scope.inviteShareplanModelConfig = {};
    $scope.voteConfirmAlertConfig = {};
    $scope.displayCreateExtn = false;
    $scope.extnRequestModelConfig = {};
    // Tax reclaim events Option click callback
    $scope.optionClickCallback = function (
      workStationId,
      event,
      dwtEventDetails
    ) {
      if (event === 'edit') {
        $state.go('editDWT', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          eventStatus: dwtEventDetails.status,
          dwtPage: 'editDwt',
          eventId: dwtEventDetails.eventId,
          breadCrumbDetails: [],
        });
      } else if (event === 'delete') {
        $scope.deleteGmID = dwtEventDetails.eventId;
        $scope.status = dwtEventDetails.status;
        $scope.approveDelete($scope.deleteGmID, $scope.status);
      } else if (event === 'createPE') {
        $state.go('createPEClaim', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          eventId: dwtEventDetails.eventId,
          shareholderId: dwtEventDetails.shareholderId,
          intermediaryId: $sessionStorage.groupId,
          loginUserId: $scope.userId,
          isin: dwtEventDetails.isin,
          dwtPerSecurity: dwtEventDetails.dwtPerSecurity,
          issuerId: dwtEventDetails.issuerId,
          flag: 'claims',
          eventStatus: dwtEventDetails.status,
          eventName: dwtEventDetails.eventName,
          issuerName: dwtEventDetails.issuerName,
          qrfEndDate: dwtEventDetails.qrfEndDateStr,
          peFlag: dwtEventDetails.peFlag,
          qrfFlag: dwtEventDetails.qrfFlag,
          extReqFlag: dwtEventDetails.extensionReqFlag,
          extensionReqFlagForQRF: dwtEventDetails.extensionReqFlagForQRF,
          extensionReqFlagForPE: dwtEventDetails.extensionReqFlagForPE,
          shareClassName: dwtEventDetails.shareClassName,
          shareClassId: dwtEventDetails.shareClassId,
          breadCrumbDetails: [],
        });
      } else if (event === 'createQRF') {
        $state.go('createQRFClaim', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          eventId: dwtEventDetails.eventId,
          shareholderId: dwtEventDetails.shareholderId,
          intermediaryId: $sessionStorage.groupId,
          loginUserId: $scope.userId,
          isin: dwtEventDetails.isin,
          dwtPerSecurity: dwtEventDetails.dwtPerSecurity,
          issuerId: dwtEventDetails.issuerId,
          flag: 'claims',
          eventStatus: dwtEventDetails.status,
          eventName: dwtEventDetails.eventName,
          issuerName: dwtEventDetails.issuerName,
          qrfEndDate: dwtEventDetails.qrfEndDateStr,
          peFlag: dwtEventDetails.peFlag,
          qrfFlag: dwtEventDetails.qrfFlag,
          extReqFlag: dwtEventDetails.extensionReqFlag,
          extensionReqFlagForQRF: dwtEventDetails.extensionReqFlagForQRF,
          extensionReqFlagForPE: dwtEventDetails.extensionReqFlagForPE,
          shareClassName: dwtEventDetails.shareClassName,
          shareClassId: dwtEventDetails.shareClassId,
          breadCrumbDetails: [],
        });
      } else if (event === 'bulkUploadQRF') {
        $state.go('qrfBulkUpload', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          eventId: dwtEventDetails.eventId,
          issuerId: dwtEventDetails.issuerId,
          issuerName: dwtEventDetails.issuerName,
          eventName: dwtEventDetails.eventName,
          isin: dwtEventDetails.isin,
          dwtPerSecurity: dwtEventDetails.dwtPerSecurity,
          shareholderId: dwtEventDetails.shareholderId,
          flag: 'claims',
          eventStatus: dwtEventDetails.status,
          qrfEndDate: dwtEventDetails.qrfEndDateStr,
          peFlag: dwtEventDetails.peFlag,
          qrfFlag: dwtEventDetails.qrfFlag,
          extensionReqFlagForQRF: dwtEventDetails.extensionReqFlagForQRF,
          extensionReqFlagForPE: dwtEventDetails.extensionReqFlagForPE,
          extReqFlag: dwtEventDetails.extensionReqFlag,
          shareClassName: dwtEventDetails.shareClassName,
          shareClassId: dwtEventDetails.shareClassId,
        });
      } else if (event === 'notification') {
        $state.go('dwtNotification', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          eventId: dwtEventDetails.eventId,
          breadCrumbDetails: [dwtEventDetails.eventName],
          issuerId: dwtEventDetails.issuerId,
        });
      } else if (event === 'viewExtnReq') {
        $state.go('dwtSingleExtensionRequest', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          peEndDate: dwtEventDetails.peEndDate,
          qrfEndDate: dwtEventDetails.qrfEndDate,
          peEndDateStr: dwtEventDetails.peEndDateStr,
          qrfEndDateStr: dwtEventDetails.qrfEndDateStr,
          peFlag: dwtEventDetails.peFlag,
          qrfFlag: dwtEventDetails.qrfFlag,
          extensionReqFlagForQRF: dwtEventDetails.extensionReqFlagForQRF,
          extensionReqFlagForPE: dwtEventDetails.extensionReqFlagForPE,
          eventName: dwtEventDetails.eventName,
          issuerName: dwtEventDetails.issuerName,
          eventId: dwtEventDetails.eventId,
          issuerId: dwtEventDetails.issuerId,
          shareClassName: dwtEventDetails.shareClassName,
          isin: dwtEventDetails.isin,
          paymentDate: dwtEventDetails.paymentDateStr,
          taSubmissionDate: dwtEventDetails.taSubmissionDateStr,
          breadCrumbDetails: [dwtEventDetails.meetingName],
        });
      } else if (event === 'share') {
        AgentService.importDocFiles(generalMeeting.generalMeetingId).then(
          function (response) {
            $state.go('inviteSharePlan', {
              selectedIndex: 1,
              meetingStatus: generalMeeting.generalMeetingId,
              issuerId: generalMeeting.issuerId,
              gmDate: generalMeeting.generalMeetingDateStr,
              issuerName: generalMeeting.issuerName,
              breadCrumbDetails: [generalMeeting.meetingName],
            });
          },
          function (error) {
            if (error.status === 422) {
              $scope.inviteShareplanModelConfig.modalBody =
                error.data.errors[0].errorMessage;
              ModelWindowService.showModelWindow('inviteSharePlanAlert');
            }
          }
        );
      } else if (event === 'sendEmailToTaxAuthority') {
        $state.go('dwtDetails', {
          eventId: dwtEventDetails.eventId,
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          eventName: dwtEventDetails.eventName,
          eventStatus: dwtEventDetails.status,
          issuerId: dwtEventDetails.issuerId,
          issuerName: dwtEventDetails.issuerName,
          dwtPerSecurity: dwtEventDetails.dwtPerSecurity,
          qrfEndDate: dwtEventDetails.qrfEndDate,
          flag: 'sendEmail',
          peFlag: dwtEventDetails.peFlag,
          qrfFlag: dwtEventDetails.qrfFlag,
          extReqFlag: dwtEventDetails.extensionReqFlag,
          extensionReqFlagForQRF: dwtEventDetails.extensionReqFlagForQRF,
          extensionReqFlagForPE: dwtEventDetails.extensionReqFlagForPE,
          shareholderId: dwtEventDetails.shareholderId,
          breadCrumbDetails: [dwtEventDetails.eventName],
        });
      } else if (event === 'createExtensionRequest') {
        $state.go('createDwtExtensionRequest', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          peEndDateStr: dwtEventDetails.peEndDateStr,
          qrfEndDateStr: dwtEventDetails.qrfEndDateStr,
          peEndDate: dwtEventDetails.peEndDate,
          qrfEndDate: dwtEventDetails.qrfEndDate,
          peFlag: dwtEventDetails.peFlag,
          qrfFlag: dwtEventDetails.qrfFlag,
          eventName: dwtEventDetails.eventName,
          peExtReqFlag: dwtEventDetails.extensionReqFlagForPE,
          qrfExtReqFlag: dwtEventDetails.extensionReqFlagForQRF,
          issuerName: dwtEventDetails.issuerName,
          eventId: dwtEventDetails.eventId,
          issuerId: dwtEventDetails.issuerId,
          shareClassName: dwtEventDetails.shareClassName,
          isin: dwtEventDetails.isin,
          paymentDate: dwtEventDetails.paymentDateStr,
          taSubmissionDate: dwtEventDetails.taSubmissionDateStr,
          breadCrumbDetails: [dwtEventDetails.meetingName],
        });
      } else if (event === 'downloadDocument') {
        const downloadUrl = 'v1/dwtevents/' +
          $sanitize(dwtEventDetails.eventId) +
          '/downloadAllDocuments';
        $scope.loadingDownload = true;
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            cbpDownloadBlob(response.file, response.fileName);
            $scope.loadingDownload = false;
          },
          function (error) {
            $scope.loadingDownload = false;
          }
        );
      } else if (event === 'rejectVC') {
        $scope.rejectGMId = dwtEventDetails.generalMeetingId;
        $scope.gmName = dwtEventDetails.meetingName;
        $scope.gmIssuerName = dwtEventDetails.issuerName;
        $scope.decision = 'no';
        $scope.modalBody = $filter('translate')('areSureDecline_modal');
        $scope.modalLeftButton = $filter('translate')('label_confirm');
        $scope.modalRightButton = $filter('translate')('label_cancel');
      } else if (event === 'approveVC') {
        $scope.rejectGMId = dwtEventDetails.generalMeetingId;
        $scope.gmName = dwtEventDetails.meetingName;
        $scope.gmIssuerName = dwtEventDetails.issuerName;
        $scope.decision = 'yes';
        $scope.modalBody = $filter('translate')('areSureAccept_modalVc');
        $scope.modalLeftButton = $filter('translate')('label_confirm');
        $scope.modalRightButton = $filter('translate')('label_cancel');
      }
    };

    //The below function is to fetch event list.
    $scope.getDWTEventData = function () {
      $scope.loading = true;
      $scope.searchViewPage = false;
      $scope.sortType = 'issuer';
      $scope.sortReverse = false;
      DwtHomeService.dwtHome().then(
        function (data) {
          $scope.dwtHomeData = data.data;
          angular.forEach($scope.dwtHomeData, function (value) {
            if (value.status === 'R') {
              value.status = 'D';
            }
            if (
              value.paymentDate === null ||
              value.paymentDate === undefined ||
              value.paymentDateStr === '01 Jan 1970'
            ) {
              value.paymentDateStr = '';
            }
            if (
              value.peEndDate === null ||
              value.peEndDate === undefined ||
              $filter('date')(new Date(value.peEndDate), 'yyyy-MM-dd') ===
                '1970-01-01'
            ) {
              value.peEndDateStr = '';
            } else {
              value.peEndDateStr = value.peEndDateStr + ' CET';
            }
            if (
              value.qrfEndDate === null ||
              value.qrfEndDate === undefined ||
              $filter('date')(new Date(value.qrfEndDate), 'yyyy-MM-dd') ===
                '1970-01-01'
            ) {
              value.qrfEndDateStr = '';
            } else {
              value.qrfEndDateStr = value.qrfEndDateStr + ' CET';
            }
          });
          $scope.modalBody = $filter('translate')('areSureDelete_modal');
          $scope.modalLeftButton = $filter('translate')('label_confirm');
          $scope.modalRightButton = $filter('translate')('label_cancel');
          $scope.loading = false;
        },
        function (error) {
          $scope.loading = false;
        }
      );
    };
    // get mock event details for create Extension req.
    var generalMeeting = null;

    $scope.createPeQrfRoute = function (routeFlag) {
      if (routeFlag === 'PE') {
        $state.go('createPEClaim');
      } else {
        $state.go('createQRFClaim');
      }
    };
    $scope.extentionRequestLanding = function () {
      // logic based on backend confirmation.
      ExtensionRequestService.getExtensionReqStatus(
        generalMeeting.generalMeetingId,
        $sessionStorage.groupId
      )
        .query()
        .$promise.then(
          function (data) {
            if (data.code === 'CBP_MSG_45' || data.code === 'CBP_MSG_51') {
              $scope.extReqRejected = true;
              $state.go('createDwtExtensionRequest', {
                intermediaryDeadlineDateStr:
                  generalMeeting.intermediaryDeadlineDateStr,
                gmName: generalMeeting.meetingType,
                gmDateTime: generalMeeting.generalMeetingDateStr,
                generalMeetingId: generalMeeting.generalMeetingId,
                extReqRejected: $scope.extReqRejected,
                issuerId: generalMeeting.issuerId,
                breadCrumbDetails: [generalMeeting.meetingName],
              });
            } else {
              $scope.extReqRejected = false;
              $scope.extnRequestModelConfig.modalHeader =
                'Cannot create extension request!';
              $scope.extnRequestModelConfig.modalBody = data.message;
              ModelWindowService.showModelWindow('extReqAlertBox');
              $('ext_dwt_modal').modal('show');
            }
          },
          function (error) {
            $scope.extReqRejected = false;
            $scope.extnRequestModelConfig.modalHeader =
              'Cannot create extension request!';
            $scope.extnRequestModelConfig.modalBody =
              error.data.errors[0].errorMessage;
            ModelWindowService.showModelWindow('extReqAlertBox');
            $('ext_dwt_modal').modal('show');
          }
        );
    };

    $scope.approveDelete = function (eventId, status) {
      $scope.eventId = eventId;
      if (status == 'P' || status == 'T') {
        $scope.modalHeader = $filter('translate')('label_dwtCancelReq');
        $scope.modalBody = $filter('translate')(
          'label_dwtModalConfirmCancelTemplate'
        );
        $scope.modalFooter = '';
        $scope.modalLeftButton = $filter('translate')('label_yes');
        $scope.modalRightButton = $filter('translate')('label_no');
      } else {
        $scope.modalHeader = $filter('translate')('label_dwtReq');
        $scope.modalBody = $filter('translate')(
          'label_dwtModalConfirmDelTemplate'
        );
        $scope.modalFooter = '';
        $scope.modalLeftButton = $filter('translate')('label_yes');
        $scope.modalRightButton = $filter('translate')('label_no');
      }
    };

    $scope.deleteEvent = function () {
      var request = {
        loggedInUserId: $sessionStorage.userId,
        eventId: $scope.deleteGmID,
        status: 'Cancelled',
        workstation: $sessionStorage.usertype,
      };
      createEditDwtService
        .deleteDwtEvent($scope.deleteGmID, request, 'secureToken')
        .then(
          function (response) {
            $scope.success = true;
            $scope.message = response.data.message;
            $('#successMsg').modal('show');
            $timeout(function () {
              $('#successMsg').modal('hide');
              $('.modal-backdrop').remove();
            }, 3000);
            $scope.getDWTEventData();
          },
          function (error) {
            $scope.message = null;
            $scope.failure = true;
          }
        );
    };

    //Below function is to search events based on the issuer name entered in search box
    $scope.onIssuerSearchChange = function () {
      $scope.issuerSearch.edit = true;
    };

    $scope.getDWTEventData();
  },
]);
